@b notification-banner {
  display: flex;
  height: 3.3125rem;
  padding: 0 1rem;
  background: orange;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;

  @e text {
    padding: 0 1rem;
    font-weight: bold;
    text-align: center;
  }
  @e warning {
    flex: auto 0 0;
    font-size: 2rem !important;
  }
  @media (--only-mobile) {
    max-height: 48px;
  }
}
