

@component-namespace profile {
  @b container {
    padding: 40px;
    margin-top: 70px;
    background: var(--white-background-color);
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05),
      0px 0px 2px 2px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(237, 237, 237, 1);

    @e title {
      line-height: 1.5;
      font-size: 30px;
      font-family: Nunito;
      font-weight: bold;
      color: var(--text-dark-color);
    }

    @e line {
      width: 60px;
      height: 2px;
      background: var(--accent-color);
      margin-bottom: 30px;
      margin-top: 8px;
    }

    @e label {
      text-align: right;
      font-size: 14px;
      color: var(--grey-border-color);
      line-height: 19px;
      margin: 16px 0;
      @s pwd {
        margin-right: 20px;
      }
    }

    @e message {
      font-size: 14px;
      color: var(--text-dark-color);
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    @e input {
      width: 170px;
    }
  }
  @b container .ant-form-item-label label:after {
    content: '';
  }

  @b container .ant-form-item-control {
    margin-left: 20px;
  }
}
