@component-namespace home {
  @b tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-dark-color);
    @s workspace {
      margin-bottom: 20px;
    }
  }

  @b table-wrap {
    border: 1px solid rgba(237, 237, 237, 1);
    margin-bottom: 50px;
    border-radius: 4px;
    background-color: var(--white-background-color);
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05),
      0px 0px 2px 2px rgba(0, 0, 0, 0.03);
  }

  @b tab-label {
    font-size: 24px;
    font-family: Dosis;
  }

  @b ws-empty-block {
    text-align: center;
    padding: 30px 0;
  }

  @b ws-row {
    cursor: pointer;
    padding: 0 24px;
    &:last-child {
      & td {
        border-bottom: none;
      }
    }
  }

  @b ws-empty-title {
    font-size: 20px;
    color: var(--text-dark-color);
    font-family: Dosis;
    font-weight: bold;
    margin-top: 20px;
  }

  @b ws-empty-link {
    color: var(--accent-color);
    cursor: pointer;
    margin-left: 5px;
  }

  @b ws-empty-tips {
    color: var(--grey-border-color);
    font-size: 14px;
    margin: 10px 0 20px 0;
  }

  @b ws-empty-guide {
    margin-top: 10px;
    color: var(--accent-color);
  }

  @b add-worksapce-icon {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;

    & path {
      fill: var(--accent-color) !important;
    }

    & #Rectangle {
      fill: white !important;
      stroke: var(--accent-color) !important;
    }
  }
}
