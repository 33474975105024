@component-namespace collection {
  @b link {
    cursor: pointer;
    &:hover {
      color: var(--accent-color);
    }
  }

  @b datasource {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    @e name {
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 10px;
      text-overflow: ellipsis;
    }

    & button {
      margin: 0 5px;
      border-radius: 2px;
    }
  }

  @b detail {
    @e title {
      padding-top: 11px;
      height: 90px;
      background: var(--secondary-background-light);
      border-top: 1px solid var(--layout-border-color);
      border-bottom: 1px solid var(--layout-border-color);
    }

    @e edit {
      border-radius: 2px !important;
      margin-top: 24px;
      font-size: 12px !important;
      margin-right: 17px;
    }

    @e name_icon {
      font-size: 20px;
      position: relative;
      top: -5px;
    }

    @e status {
      height: 70px;
      min-width: 100px;
      border-radius: 4px;
      background-color: var(--status-offline-color);
      text-align: center;
      color: var(--primary-color);
      font-weight: 500;
      float: right;
      position: relative;

      @s collection {
        width: 8px;
        border-radius: 0;
      }

      @s online {
        background-color: var(--status-online-color);
      }
      @s good {
        background-color: var(--status-good-color);
      }
      @s hazardous {
        background-color: var(--status-hazardous-color);
      }
      @s moderate {
        background-color: var(--status-moderate-color);
      }
      @s sensitive {
        background-color: var(--status-sensitive-color);
      }
      @s unhealthy {
        background-color: var(--status-unhealthy-color);
      }
      @s very-unhealthy {
        background-color: var(--status-very-unhealthy-color);
      }
      @s excellent {
        background-color: var(--status-excellent-color);
      }
    }

    @e status_num {
      display: block;
      height: 50px;
      font-size: 30px;
      font-weight: 500;
      line-height: 50px;
    }

    @e status_text {
      display: block;
      font-size: 12px;
      position: relative;
      top: -5px;
    }

    @e status_unit {
      color: var(--status-offline-color);
      border: 1px solid var(--status-offline-color);
      border-radius: 4px;
      font-size: 12px;
      padding: 0 3px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background: var(--primary-color);
      position: absolute;
      top: -5px;
      right: -20px;

      @s online {
        color: var(--status-online-color);
        border-color: var(--status-online-color);
      }
      @s good {
        color: var(--status-good-color);
        border-color: var(--status-good-color);
      }
      @s hazardous {
        color: var(--status-hazardous-color);
        border-color: var(--status-hazardous-color);
      }
      @s moderate {
        color: var(--status-moderate-color);
        border-color: var(--status-moderate-color);
      }
      @s sensitive {
        color: var(--status-sensitive-color);
        border-color: var(--status-sensitive-color);
      }
      @s unhealthy {
        color: var(--status-unhealthy-color);
        border-color: var(--status-unhealthy-color);
      }
      @s very-unhealthy {
        color: var(--status-very-unhealthy-color);
        border-color: var(--status-very-unhealthy-color);
      }
      @s excellent {
        color: var(--status-excellent-color);
        border-color: var(--status-excellent-color);
      }
    }

    @e row {
      display: flex;
      /* align-items: flex-start; */
      /* padding-top: 22px; */
      position: relative;
    }

    @e row_left {
      width: 50%;
      margin-top: 1px;
      margin-left: -75px;
      text-align: right;
      @media (--only-mobile) {
          display: none;
      }
    }

    @e row_right {
      flex: 1;
      margin-left: 50px;

      & .edit-disabled-text {
        padding: 0;
      }
    }

    @e name {
      color: var(--text-dark-color);
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      margin-top: 25px;
      margin-bottom: 14px;

      @m input {
        margin-top: 20px;
        margin-bottom: 9px;
        font-size: 24px !important;
        line-height: 32px !important;

        &.ant-input {
          width: 300px !important;
        }

        &.ant-input-disabled {
          text-overflow: ellipsis;
          padding-left: 0 !important;
        }
      }
    }

    @e tab {
      color: var(--grey-border-color);
      font-size: 14px;
      line-height: 25px;
      margin-right: 46px;
      display: inline-block;
      cursor: pointer;
      @s active {
        color: var(--accent-color);
        font-weight: bold;
        border-bottom: 2px solid var(--accent-color);
      }
    }

    @e text_row {
      font-size: 14px;
      line-height: 19px;
      vertical-align: middle;

      & > .collection-detail-row_left {
        color: var(--grey-border-color);
      }

      & > .collection-detail-row_right {
        color: var(--text-dark-color);
      }

    }
  }

  @b edit_row {
    overflow: hidden;
    & > span {
      display: inline-block;
      line-height: 25px;
      float: left;
      width: 190px;
      border: 1px solid var(--secondary-background-dark);
      padding: 0 15px;
      overflow: hidden;
      min-height: 27px;
      text-overflow: ellipsis;
    }
    @e button.ant-btn {
      font-size: 12px !important;
      border-radius: 0;
      height: 26px;
    }
  }

  @b select {
    width: 127px;
  }

  @b del_button {
    margin: 24px auto;
    & > .ant-btn {
      width: 320px;
      margin-left: 20px;
      font-size: 12px;
      border-radius: 2px;
    }

    @s collection {
      & > .ant-btn {
        margin-left: 50px;
        width: 270px;
      }
    }
  }

  @b date {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }

  @b add_text {
    font-weight: bolder;
    font-size: 16px;
    line-height: 22px;
    vertical-align: middle;
    margin-left: -23px;
    cursor: pointer;

    & > i {
      background: var(--accent-color);
      color: var(--text-white-color);
      font-size: 15px;
      width: 15px;
      border-radius: 50%;
      position: relative;
      bottom: -2px;
      margin-right: 8px;
    }
  }

  @b table {
    @e parmeter > span {
      margin: 0 5px;
      color: var(--accent-color);
      font-weight: bold;
    }

    @e dropdown_item.small_text {
      padding: 8px 20px;
    }

    @e no_expand .ant-table-row-expand-icon {
      display: none;
    }
  }

  @b indicator {
    @e container {
      background: #f9f9f9;
      padding: 9px 0;
    }

    @e container span {
      font-size: 12px;
      /* font-family: Nunito; */
      line-height: 16px;
    }

    @e row {
      margin: 5px 0;
      @s title span {
        font-weight: bold;
        color: var(--text-dark-color);
      }
    }

    @e text {
      display: inline-block;
      width: 225px;
    }

    @e icon {
      display: inline-block;
      width: 75px;
      & a:hover {
        color: var(--danger-color);
      }
    }
  }

  @b content {
    @e right {
      flex: 1;
    }

    @e title {
      font-size: 12px;
      line-height: 16px;
      padding-top: 6px;
      display: flex;
      justify-content: space-between;
    }

    @e total {
      float: right;
    }
  }

  @b table {
    & th:first-child {
      padding-left: 2.125rem !important;
    }

    & td:first-child {
      padding-left: 2.125rem !important;
    }
  }
  @b input.ant-input-disabled {
    width: 80px !important;
  }

  @media (--only-mobile) {
    @b detail {
      @e row_right {
        margin-left: 0px;
        padding: 0 1rem;
      }
    }
  }
}
