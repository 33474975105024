

@component-namespace location {
  @b wrapper {
    max-width: var(--desktop-min-width);
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  @b inner {
    width: var(--desktop-min-width);
    @m home {
      width: var(--desktop-min-width);
      padding-top: 20px;
    }
  }

  @b inner .ant-tabs-tab-active {
    font-weight: 700 !important;
  }
}

@media (--only-not-desktop) {
  @component-namespace location {
    @b wrapper {
      overflow-x: auto;
    }
  }
}

@media (--only-mobile) {
  @component-namespace location {
    @b wrapper {
      overflow: hidden;
    }
  }
}
