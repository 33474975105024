@component-namespace search {
  @b container {
    width: calc(100% - 2px);
    border: 1px solid var(--layout-border-color);
  }

  @b input {
    & .ant-input {
      border-color: transparent;
      font-size: 0.75rem;
      font-family: Nunito;
      padding-left: 3.125rem !important;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }

      &::-webkit-input-placeholder {
        color: #909090;
      }

      &::-moz-input-placeholder {
        color: #909090;
      }

      &::-ms-input-placeholder {
        color: #909090;
      }
    }

    & .ant-input-suffix {
      display: none;
    }
  }
}
