@component-namespace report {
  @b container {
    position: relative;
    margin-top: 24px;
    padding: 0 6px 0 20px;
  }

  @b filter {
    margin: 24px 0 16px 0;
  }

  @b table {
    @e icon {
      padding: 0 !important;
      text-align: center !important;
      cursor: pointer;

      @s primary {
        &:hover {
          & path {
            fill: var(--accent-color);
          }

          & i {
            color: var(--accent-color);
          }
        }
      }

      @s danger {
        &:hover {
          & path {
            fill: var(--danger-color);
          }

          & i {
            color: var(--danger-color);
          }
        }
      }

      @s disable {
        cursor: not-allowed;
        & path {
          fill: rgba(0, 0, 0, 0.25) !important;
        }

        & i {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }

  @b separator {
    position: relative;
    bottom: -5px;
  }

  @b title {
    display: flex;
    justify-content: space-between;
    @e label {
      line-height: 38px;
      font-size: 30px;
      font-family: Dosis;
    }

    @e button {
      float: right;
      margin-top: -36px;
      margin-right: 6px;
    }
  }

  @b small_message {
    margin: 8px 0;
  }

  @b new_tips {
    background: #f5f5f5;
    padding: 8px;
    margin-top: 14px;

    @e close {
      float: right;
      color: var(--text-dark-color);
    }
  }

  @b card {
    height: 200px;
    margin: 10px 0;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: var(--base-transition);
    &:hover {
      box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16),
        0 3px 6px 2px rgba(0, 0, 0, 0.09);
    }
    @s add {
      /* border: 1px dashed var(--grey-border-color); */
      background: #f6f6f6;
      border-raduis: 6px;
      text-align: center;
      & > .material-icons {
        font-size: 48px;
        text-align: center;
        margin: 36px auto;
        color: var(--grey-border-color);
      }

      &:hover {
        background: var(--secondary-background-light-mobile);
        & > .material-icons {
          color: var(--accent-color);
        }

        & > p {
          color: var(--accent-color);
        }
      }
    }

    @e new {
      position: absolute;
      top: 6px;
      left: 20px;
      font-size: 36px !important;
      color: var(--danger-color);
    }

    @e new_content {
      position: relative;
      border: 13px solid var(--danger-color);
      width: 0px;
      box-sizing: content-box;
      height: 17px;
      border-width: 10px 13px;
      border-bottom-color: transparent;

      &:after {
        content: "New";
        position: absolute;
        top: -2px;
        left: -12px;
        font-size: 12px;
        color: var(--text-white-color);
      }
    }

    @e msg {
      padding: 48px 20px 10px 20px;
    }

    @e name {
      padding: 0 20px;
      line-height: 43px;
      font-size: 32px;
      font-family: Nunito;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @e bottom {
      /* height: 50px; */
      border-top: 1px solid var(--layout-border-color);
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    @e bottom_button {
      display: inline-block;
      margin-right: 24px;
      cursor: pointer;
      color: var(--grey-border-color);
      &:hover {
        color: var(--accent-color);
      }
      & > icon {
        margin-right: 4px;
        color: var(--grey-border-color);
        &:hover {
          color: var(--accent-color);
        }
      }
    }

    @e more {
      color: var(--grey-border-color);
      float: right;
      position: relative;
      top: 2px;
    }

    @e menu {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 12px;

      & .anticon {
        margin-right: 16px;
      }

      &:hover {
        color: var(--accent-color);
        & .anticon {
          color: var(--accent-color);
        }
      }
    }
  }

  @b add {
    height: 80px;
    margin-top: 24px;
    border: 1px dashed var(--grey-border-color);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--base-transition);
    & .material-icons {
      font-size: 40px;
    }

    &:hover {
      border-color: var(--accent-color);
      color: var(--accent-color);
    }

    @e message {
      display: inline-block;
      margin: 0 16px;
      /* position: relative;
      top: -2px; */
    }
  }

  @b modal {
    @e preview {
      margin: 15px 0;
    }

    @e preview_image {
      margin-top: 8px;
      border: 1px dashed rgba(202, 202, 202, 1);
      padding: 5px 40px;
    }

    @e tip {
      font-size: 12px;
      line-height: 16px;
      margin: 16px 0;
    }
  }

  @b template {
    @e card {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 24px;
      position: relative;
      margin: 24px 0;
    }

    @e label {
      font-size: 12px;
      font-family: Nunito;
      color: var(--text-dark-color);
      line-height: 16px;
      margin: 5px 0;
      @s checkbox {
        margin-left: 24px;
      }
    }

    @e name {
      min-width: 130px;
      display: inline-block;
    }

    @e close {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 10;
      color: #cacaca;
      &:hover {
        color: var(--danger-color);
      }
    }

    @e check_group {
      width: 100%;
      margin-top: 15px !important;
    }

    @e collapse {
      & .ant-collapse-header {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      & .ant-collapse-item {
        border: none !important;
      }
    }
  }

  @b preview {
    @e title {
      font-size: 24px;
      font-family: Nunito;
      color: var(--text-dark-color);
      line-height: 32px;
      margin-bottom: 16px;
      margin-top: 24px;
    }

    @e img {
      padding: 13px 48px;
      border-bottom: 1px solid #efefef;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  @b request {
    margin-top: 48px;
    padding-bottom: 200px;
    @e location {
      width: 332px;
    }
  }

  @b success {
    padding: 200px 0;
    text-align: center;
    @e icon {
      font-size: 48px;
    }
    @e title {
      margin: 8px 0;
      font-size: 24px;
      line-height: 33px;
    }

    @e message {
      width: 500px;
      margin: 0 auto;
    }

    @e button {
      margin-top: 48px;
    }
  }

  @b affix {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(101%);
    /* padding-left: 0 !important; */
    @e list {
      margin: 16px 0;
      padding-left: 0;
      font-size: 12px;
      border-left: 1px solid var(--grey-border-color);
    }

    @e link {
      white-space: nowrap;
    }
  }

  @b view {
    @e title {
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      padding: 25px 40px;
      font-family: Dosis;
      border-bottom: 1px solid #efefef;
    }

    @e subtitle {
      font-size: 24px;
      color: var(--text-dark-color);
      font-family: Dosis;
      padding: 20px 40px;
      line-height: 31px;
    }

    @e summary {
      display: flex;
      padding: 0 40px 20px 40px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 20px;
    }
  }

  @b history {
    @e date {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
    }

    @e label {
      overflow: hidden;
      line-height: 39px;
      white-space: nowrap;
      position: relative;
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}
