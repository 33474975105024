@component-namespace report {
  @b header {
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-family: Dosis;
    display: flex;
    justify-content: space-between;
  }

  @b empty-tip {
    text-align: center;
    padding: 50px 0;
    font-weight: bold;
  }

  @b period-selector {
    width: 260px;
  }

  @b panel-footer {
    display: none;
  }

  @b content {
    background: var(--white-background-color);
    padding: 20px 0;
  }

  @b panel-header {
    border-bottom: 1px solid #efefef;
    padding: 25px 40px;
  }

  @b panel-title {
    font-size: 24px;
    color: var(--text-dark-color);
    font-family: Dosis;
  }

  @b item-bottom {
    padding-top: 30px;
  }

  @b sider {
    flex: 0 1 200px;
  }

  @b location {
    margin-right: 1rem;
    line-height: 1.6;
    min-width: 240px;

    & .ant-select-selection {
      font-size: 1.5rem;
    }
  }

  @b panel {
    border-radius: 2px;
    width: 100%;
    position: relative;
    background-color: var(--white-background-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }

  @b summary {
    display: flex;
    padding: 20px 40px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
  }

  @b summary-unit {
    padding-right: 20px;

    & + div {
      border-left: 1px solid #efefef;
      padding-left: 20px;
    }
  }

  @b summary-unit-label {
    color: var(--grey-border-color);
    font-size: 14px;
  }

  @b summary-unit-content {
    position: relative;
    display: flex;
  }

  @b v-primary {
    color: var(--text-dark-color);
    font-size: 36px;
    line-height: 1.2;
    font-family: Dosis;
    font-weight: 600;
  }

  @b v-second {
    font-size: 12px;
    color: var(--grey-border-color);
    padding-right: 2px;
  }

  @b v-left {
    text-align: right;
    line-height: 1;
  }

  @b v-right {
    margin-left: 10px;
    line-height: 1.42;
    align-self: flex-end;
  }

  @b v-top {
    position: relative;
    display: flex;
  }

  @b v-bot {
    font-size: 12px;
    color: var(--grey-border-color);
    line-height: 1.1;

    & .report-v-badge {
      margin-top: 5px;
    }

    & .report-v-primary {
      line-height: 1;
    }
  }

  @b v-bot-desc {
    margin-left: 6px;
  }

  @b v-badge {
    background-color: #d0021b;
    text-align: center;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;

    & > span {
      font-size: 12px;
      color: var(--text-white-color);
      display: block;
      transform: scale(0.8);
    }
  }

  @b v-desc {
    color: var(--grey-border-color);
    font-size: 12px;
    line-height: 1;
    margin-top: 3px;
  }

  @b v-unit {
    font-size: 12px;
    color: var(--text-dark-color);
  }

  @b bar-title {
    border-top: 1px solid #efefef;
    margin-top: 20px;
    color: var(--text-dark-color);
    font-size: 20px;
    padding: 20px 47px;
    font-family: Dosis;
  }

  @b bar-title-sub {
    font-size: 24px;
    color: var(--grey-border-color);
    margin-left: 10px;
    font-family: Dosis;
  }

  @b bar-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 47px;
    font-family: Nunito;
    align-items: center;
  }

  @b bar-legend {
    font-size: 12px;
    flex: 0 1 60%;
    margin-left: -20px;
    & span {
      border-left: 5px solid;
      padding-left: 8px;
      display: inline-block;
      margin: 5px 20px;
    }
  }

  @b filter {
    font-size: 10px;
  }
  @b filter-tag {
    padding: 2px 7px;
    margin-left: 8px;
    display: inline-block;
    color: var(--text-dark-color);
    background-color: rgba(1, 119, 157, 0.1);
  }

  @b toolbar {
    margin-bottom: 15px;
  }

  @b download {
    margin-left: 15px;
  }
}
