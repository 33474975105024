.covidScoreExplanation {
  margin: 0 0 2vh 0;
}
.covidOptimisationLevel {
  margin: 2vh 0 2vh 0;
}
.covidColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1vh;
}

.covidBoldIndicator {
  font-weight: 800;
}
.clickableCanvas {
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    width: 285px;
    height: 270px;
  }
  @media screen and (min-width: 900px) {
    height: 283px;
    width: 300px;
  }
  @media screen and (min-width: 1600px) {
    width: 310px;
    height: 290px;
  }
  @media screen and (min-width: 1800px) {
    width: 340px;
    height: 310px;
  }
  @media screen and (min-width: 2200px) {
    width: 450px;
    height: 390px;
  }
}

.clickableElement {
  width: 38px;
  height: 38px;
  text-align: center;
  margin: 130px auto 0;
  cursor: pointer; 
}
.downloadBtnContainer {
  width: 80px;
  text-align: center;
  margin: 130px 20px 0px 0px;
  cursor: pointer;
}