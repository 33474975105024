@component-namespace download {
  @b content {
    margin: 0 auto;
    padding: 20px 0;
  }
  @b header-bar {
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  @b tip {
    font-size: 12px;
    color: var(--grey-border-color);
    margin-top: 5px;
  }

  @b status-success {
    color: var(--accent-color);
  }

  @b status-pending {
    color: var(--grey-border-color);
  }

  @b status-fail {
    color: var(--danger-color);
  }

  @b td-btn {
    color: var(--grey-border-color);
    cursor: not-allowed;
    &.active {
      color: var(--accent-color);
      cursor: pointer;
    }
  }

  @b period {
    color: var(--grey-border-color);
    line-height: 1;
  }
}

.break-words {
  word-break: break-all;
  line-height: 1.1;
}
