@component-namespace datatable {
  @b header {
    font-size: 1.1rem;
    margin-bottom: 30px;
    padding-top: 30px;
    font-family: Dosis;
    display: flex;
  }
  @b content {
    padding-top: 2rem;
  }
  @b header.primary {
    margin-bottom: 10px;
  }
  /* @b header .ant-select-selection__clear {
    top: 0;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
  } */
  @b header-right .label {
    margin-bottom: 5px;
  }
  @b average-label {
    margin-left: 5px;
    display: inline-block;
    padding: 5px;
    line-height: 1;
  }
  @b valid {
    background-color: rgba(0, 107, 148, 0.05);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 24px;
  }

  @b calibrated {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    line-height: 24px;

    & svg {
      vertical-align: middle;
      width: 14px;
    }
  }
  @b header-selector {
    display: flex;
    justify-content: space-between;
  }
  @b time-item {
    position: relative;
  }
  @b wh-icon {
    position: absolute;
    left: 50%;
    margin-left: 54px;
    font-size: 14px;
    color: var(--accent-color);
    display: inline-block;
    vertical-align: middle;
  }
  @b dropdown {
    position: relative;
    margin-right: 8px;
    min-width: 60px;
  }
  @b dropdown .ant-select-disabled {
    display: none;
  }
  @b dropdown-label {
    font-size: 0.8rem;
    position: absolute;
    top: -20px;
    color: var(--grey-border-color);
  }
  @b dropdown-item {
    color: var(--accent-color);
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--accent-color);
  }
  @b panel {
    border-radius: 2px;
    width: 880px;
    position: relative;
    background-color: var(--white-background-color);
    margin-bottom: 20px;
  }

  @b date-bar {
    position: absolute;
    left: -70px;
    width: 60px;
    top: 0;
    text-align: right;
    border-right: 1px solid #efefef;
    & .date-year-group {
      margin-bottom: 10px;
    }

    & .date-item-year {
      border: 1px solid #cacaca;
      font-size: 14px;
      color: #cacaca;
      padding: 2px 7px;
      border-radius: 4px;
    }
    & .date-item-year.active {
      color: var(--accent-color);
      border-color: var(--accent-color);
    }
    & .data-item-month {
      font-size: 14px;
      color: #cacaca;
      line-height: 19px;
      cursor: pointer;
      padding: 10px 10px 0 0;
      position: relative;
    }
    & .data-item-month:after {
      content: "·";
      position: absolute;
      top: 9px;
      right: -5px;
      font-size: 38px;
    }
    & .data-item-month.active {
      color: var(--accent-color);
    }
  }

  @b panel-header {
    margin-bottom: 10px;
    padding-left: 20px;
  }

  @b day-end-line > td {
    border-bottom-color: var(--grey-border-color) !important;
  }

  @b empty-tip {
    text-align: center;
    padding: 50px;
  }

  @b datatarget {
    border-bottom: 1px solid var(--accent-color);
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
  }
}
.shadow-right:after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  right: -16px;
  width: 16px;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.day {
  margin-right: 10px;
  color: var(--accent-color);
  font-size: 22px;
}
.date {
  font-size: 22px;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  position: relative;
}

.download-modal-title {
  color: var(--text-grey-color);
  font-size: 24px;
  font-weight: bold;
  font-family: Dosis;
  margin-bottom: 5px;
}
.download-modal-sub-title {
  font-size: 12px;
  color: var(--grey-border-color);
}
.download-modal-span {
  font-family: Dosis;
  color: var(--grey-border-color);
  font-size: 12px;
}
.download-modal-date {
  font-size: 14px;
  color: #8b8b8b;
  font-family: Nunito;
}
.download-modal-label {
  color: var(--text-dark-color);
  font-size: 14px;
  text-align: right;
  width: 100px;
  display: inline-block;
  font-family: Dosis;
  font-weight: bold;
  margin-right: 10px;
}
.download-modal-line {
  margin: 10px 0;
}
.download-modal-footer {
  padding: 0 20px;
  display: flex;
}
.download-modal-footer button {
  flex: 1;
}
.download-modal-footer.center {
  text-align: center;
}
.download-modal-input {
  padding-left: 100px;
  position: relative;
  margin: 10px 0;
}
.download-modal-input input {
  border: none;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  outline: 0;
}
.download-modal-input input:focus {
  box-shadow: none;
}
.download-modal-input .download-modal-label {
  position: absolute;
  left: 0;
  top: 5px;
}
.download-modal .ant-modal-footer,
.download-modal .ant-modal-header {
  border: none;
}
.download-modal .ant-modal-title {
  font-size: 24px;
  line-height: auto;
}
.download-modal .ant-modal-content {
  padding: 20px 0;
}
.download-modal-ready {
  text-align: center;
}
.download-modal-ready-tips {
  margin-top: 20px;
}

@component-namespace datasource-selector {
  @b type {
    width: 140px;
  }

  @b label {
    font-size: 14px;
    color: var(--text-dark-color);
    margin-bottom: 10px;
  }

  @b tips {
    color: var(--text-dark-color);
    font-size: 12px;
    font-family: Nunito;
  }

  @b header {
    display: flex;
    justify-content: space-between;
  }
}
