@component-namespace kiosk {
  @b des {
    margin-top: -30px;
    margin-bottom: 30px;
  }

  @b confirm-modal {
    & .ant-modal-footer {
      border: none;
    }

    & .ant-modal-header {
      border: none;
    }
  }

  @b setting-modal {
    & .ant-modal-header {
      border: none;
    }

    & .step-title {
      margin-bottom: 10px;
      color: #4a4a4a;
    }

    & .template-img.tabbed {
      padding-left: 24px;
    }

    & .tab-desc {
      color: #9b9b9b;
      font-size: 12px;
    }

    & .step-3 {
      text-align: center;
      font-size: 12px;
      padding-top: 40px;
      line-height: 1;
    }

    & .step-3 .iconfont {
      font-size: 32px;
      color: #006b94;
    }

    & .step-3 .mtop {
      margin-top: 20px;
    }

    & .choose-collection {
      margin-top: 16px;
    }

    & .step-3 .step-title {
      font-size: 18px;
      margin-top: 24px;
    }

    & .tab-title {
      color: #5c5c5c;
      font-size: 14px;
      font-weight: bold;
    }

    & .template-img img {
      width: 100%;
    }

    & .alert-panel {
      border: 1px solid #cacaca;
      border-radius: 3px;
      padding: 8px;
    }
    & .alert-panel + .alert-panel {
      margin-top: 16px;
    }
    & .alert-panel-header .noti-item-wrap {
      display: inline-block;
    }
    & .alert-panel-title {
      font-size: 12px;
      color: #4a4a4a;
      margin-bottom: 4px;
    }
    & .alert-panel-header {
      border: 1px solid #cacaca;
      padding: 8px;
      margin-bottom: 8px;
      border-radius: 3px;
    }
    & .alert-ty-1-label {
      color: #9b9b9b;
      font-size: 12px;
    }
    & .alert-ty-2-label {
      font-size: 14px;
      color: #4a4a4a;
    }
    & .alert-ty-2-desc {
      display: inline-block;
      vertical-align: middle;
    }

    & .noti-item-wrap {
      position: relative;
      padding: 0.23rem 0.25rem 0.23rem 3.75rem;
      display: inline-block;
      vertical-align: middle;
    }
    & .noti-tableIcon {
      margin: 0;
    }
    & .noti-item-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    & .footer {
      display: flex;
      margin-top: 30px;

      & .ant-btn {
        flex: 1;
      }

      & .ant-btn + .ant-btn {
        margin-left: 40px;
      }
    }

    & .body {
      & .defence-action {
        cursor: pointer;
      }
      & .input-group + .input-group {
        margin-top: 16px;
      }
      & .input-group .advice-label {
        margin-bottom: 8px;
      }
      & .advice-label .label-priamry {
        color: #4a4a4a;
        font-size: 14px;
      }
      & .advice-label .label-tip {
        color: #9b9b9b;
        font-size: 12px;
      }
      & .defence-row {
        margin-bottom: 16px;
      }
      & .defence-add-one-btn {
        cursor: pointer;
        color: #3388a9;
        font-size: 15px;
      }
      & .defence-add-one-btn.disabled {
        color: #b1b1b1;
        pointer-events: none;
      }
      & .defence-add-one-btn i {
        margin-right: 8px;
      }
      & .ant-tabs {
        overflow: visible;
      }
      & .primary-indicator {
        width: 100%;
      }

      & .formula-label {
        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 8px;
        border-radius: 50%;
        background: var(--accent-color);
        color: white;
        text-align: center;
      }
      & .kiosk-container-top {
        display: flex;
        justify-content: space-between;
      }

      & .unit-of-type {
        width: 50%;
        padding: 0 16px;
        & .label {
          color: var(--text-dark-color);
          font-size: 12px;
          margin-right: 10px;
        }

        & .help-hint {
          font-size: 12px;
          color: var(--grey-border-color);
          margin-top: 5px;
        }
      }

      & .historical-data-limit-container {
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        & .label-container {
          width: 50%
        }
        & .label-historical-data-limit {
          color: var(--text-dark-color);
          font-size: 12px;
          margin-right: 10px;
          font-weight: 700;
        }
        & .help-hint {
          font-size: 12px;
          color: var(--grey-border-color);
          margin-top: 5px;
        }
        & .historical-data-limit-input {
          width: 120px;
          height: 30px;
          input {
            padding-right: 50px;
          }
        }
      }

      & .kiosk-modal-information-container {
        display: flex;
        padding: 0 16px;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        color: var(--text-dark-color);
        font-size: 12px;
        margin-right: 10px;
        font-weight: 700;
        text-decoration: underline;
        & .kiosk-modal-child {
          margin-top: 5px;
        }
        & .kiosk-modal-child:hover {
          font-weight: 700;
        }
      }

      & .display-option-container {
        width: 100%;
        & .label {
          color: var(--text-dark-color);
          font-size: 14px;
          margin-right: 10px;
          font-weight: 600;
        }
        & .desc {
          margin-right: 10px;
          color: var(--text-dark-color);
        }
      }

      & .display-option-container-row {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid var(--grey-border-color);
      }

      & .display-option-container-row:last-child {
        border-bottom: none;
      }


      & .display-option-container-row-right {
        text-align: right;
      }


      & .location-selector {
        display: block;
        margin-bottom: 20px;
      }

      & .upload-bg {
        margin-top: -15px;
      }

      & .upload-bg,
      & .upload-logo {
        margin-bottom: 30px;
      }

      & .check-remove {
        margin-top: 10px;
      }

      & .indicator {
        margin-bottom: 30px;
      }

      & .label {
        font-size: 15px;
        color: var(--grey-border-color);
        font-weight: bold;
      }

      & .dc-identifier {
        margin-bottom: 10px;
        color: var(--grey-border-color);
        font-size: 12px;
      }

      & .cell-label {
        line-height: 21px;
        color: #333;
      }

      & .help-link {
        margin-left: 6px;
        vertical-align: middle;
      }

      & .sm-label {
        color: var(--grey-border-color);
        font-size: 12px;
        margin-bottom: 5px;
      }

      & .data-channel-settings {
        margin-bottom: 10px;
      }
      & .data-button-unit {
        display: flex;
        align-items: center;
        margin: 20px 0 20px 0;
      }
      & .data-button-add {
        margin: 0 10px 0 0;
        background: #006b94;
        border-radius: 15px;
        color: white;
      }
      & .data-channel-settings .order-btn {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
      }
      & .data-channel-settings .order-btn .iconfont {
        font-size: 18px;
      }

      & .data-channel-advance {
        display: flex;
      }
      & .data-channel-advance .data-formula {
        flex: 2;
      }
      & .data-covid-input {
        width: 50%;
      }
      & .data-covid-info {
        margin: 10px;
      }
      & .data-channel-advance .unit-group {
        flex: 1;
        margin-left: 10px;
      }

      & .ant-upload-drag-uploading {
        height: auto !important;
      }

      & .ant-upload {
        height: 94px;
        background-size: cover;
        padding: 0;
        background-position: center center;
      }

      & .anticon {
        vertical-align: middle;
      }

      & .upload-area span {
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }

  @b list-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #4a4a4a;
    margin-bottom: 1rem;
  }
  @b list-wrap {
    padding-top: 1.5rem;
  }
  @b list-wrap .ant-breadcrumb {
    font-family: Dosis;
    font-size: 24px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d5d5d5;
  }

  @b location-info {
    font-size: 1rem;
    color: #4a4a4a;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 1rem;
  }
  @b panel {
    margin: 0.5rem 0;

    @e video {
      width: 100%;
      height: 100%;
      position: relative;
      /* display: flex; */
      /* justify-content: center; */
      /* align-items: center; */
      overflow: hidden;
      & img {
        width: 100%;
      }
      & > video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @m header {
      display: flex;
      line-height: 3rem;
      height: 3rem;
      justify-content: space-between;
      border-bottom: 1px solid #d5d5d5;
      font-family: Dosis;

      & .location-name {
        font-size: 20px;
        color: var(--text-dark-color);
      }

      & .location-switch {
        font-weight: bold;
        cursor: pointer;

        &.active .anticon {
          color: var(--accent-color);
        }

        & .anticon {
          color: #d9d9d9;
          margin-right: 10px;
        }
      }
    }

    @m body {
      padding-top: 1.2rem;
    }

    @m desc {
      flex: 0 1 500px;
      padding-top: 30px;
    }

    @m desc .title {
      color: var(--text-dark-color);
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    @m card {
      height: 128px;
      margin-bottom: 1.6rem;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06),
        0 1px 2px 0 rgba(0, 0, 0, 0.1);

      &:hover {
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.1);
      }

      &.app-add-one {
        text-align: center;
        position: relative;
      }
      &.app-add-one .app-title {
        font-size: 20px;
        margin-top: 24px;
      }
      &.app-add-one p {
        color: #9b9b9b;
        font-size: 12px;
      }
      &.app-add-one .app-button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #006b94;
        height: 36px;
        line-height: 36px;
        color: white;
        font-weight: bold;
      }

      &.add-one {
        line-height: 128px;
        text-align: center;
        font-size: 3.8rem;
        border: 2px dashed #cacaca;
        box-shadow: none;
        color: var(--input-border-color);

        &:hover .hover-show {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          line-height: 1.4;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          color: var(--text-white-color);
          font-size: 1.2rem;
        }
      }

      &.add-one .add-one-desc {
        color: #9b9b9b;
        font-size: 12px;
      }

      & .hover-show {
        display: none;
      }
    }

    @m card-footer {
      position: absolute;
      bottom: 0;
      background: var(--white-background-color);
      left: 0;
      width: 100%;
      line-height: 2rem;
      height: 2rem;
      white-space: nowrap;
      padding: 0 2rem 0 0.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: bold;
      color: var(--text-grey-color);
      font-family: Dosis;
    }

    @m card-menu {
      position: absolute;
      right: 0.4rem;
      top: 0.4rem;

      &:hover {
        opacity: 0.7;
      }

      & .icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  @b collection-hidden {
    & .desc {
      margin-right: 10px;
    }
  }

  @b color {
    @e swatch {
      padding: 4px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: inline-block;
      cursor: pointer;
    }

    @e block {
      width: 80px;
      height: 16px;
      border-radius: 2px;
    }

    @e popover {
      position: absolute;
      z-index: 2;
    }
    @e wrapper {
      position: inherit;
      z-index: 100;
    }
  }

  @b video {
    margin: 10px;
    text-align: center;
  }
  @media (--only-mobile) {
    .kiosk-panel--body {
      padding-top: 0px
    }
    .kiosk-list-title, .kiosk-search-bar, .kiosk-panel-add-one-container {
      display: none;
    }

    .kiosk-location-info {
      text-align: center;
      font-weight: 700;
    }
    .kiosk-location-kiosk-list {
      padding: 0 1rem 1rem 1rem;
    }
  }
}
.w200 {
  width: 200px !important;
}
.color-picker-block .ant-popover-inner-content {
  padding: 0;
}
.color-picker-block .ant-popover-inner-content .block-picker {
  box-shadow: none !important;
}
.anticon-environment {
  vertical-align: baseline;
}

.ql-container {
  min-height: 50vh;
}
