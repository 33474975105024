

@component-namespace register {
  @b title {
    color: var(--text-grey-color);
    font-family: Nunito;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }

  @b group {
    margin-top: 1.5rem;

    @e label {
      font-size: 0.75rem;
      line-height: 1rem;
      color: var(--text-dark-color);
      margin-bottom: 0.5rem;

      @s require::before {
        content: '*';
        color: var(--accent-color);
        font-weight: bold;
        margin-right: 3px;
        height: 100%;
        vertical-align: middle;
      }
    }

    @e icon {
      font-size: 0.75rem;
      color: var(--accent-color);
    }
  }

  @b button_group {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    & .custom-button--block.ant-btn+.ant-btn {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  @b device {
    display: flex;

    @e error-tip {
      margin: 40px 0;
      text-align: center;
    }

    @e brand-selector {
      width: 150px;
    }

    @e dropdown span img {
      height: 1rem;
      margin-left: 5px;
    }

    @e info {
      margin-left: 2.25rem;
      flex: 1;
    }

    @e column {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 2rem;
      margin-bottom: 10px;
      align-items: center;

      & > span {
        font-size: 0.75rem;
        line-height: 1rem;

        & > i {
          color: var(--accent-color);
          font-size: 0.75rem;
          margin-right: 5px;
        }

        & img {
          height: 1rem;
          margin-left: 5px;
        }
      }
    }

    @e label {
      color: var(--grey-border-color);
    }

    @e step3 {
      text-align: center;
      padding: 1rem;

      & .icon {
        font-size: 2.5rem;
        color: var(--accent-color);
      }

      & h2 {
        margin: 1rem 0;
      }

      & .desc {
        word-break: break-word;
        white-space: pre-wrap;
      }

      & a {
        color: var(--accent-color);
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }

  @b body {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @s datasource {
      margin-top: 1.5rem;
      & .ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      & .ant-form-item + .ant-form-item {
        margin-top: 16px;
      }
    }
    & .ds-img-wrap {
      text-align: center;
    }
    & .ds-img-wrap img {
      width: 170px;
      height: 170px;
    }

    & .ds-img-wrap .ds-type {
      font-size: 16px;
      color: #4a4a4a;
      vertical-align: middle;
      margin-left: 2px;
    }
    & .ds-img-wrap .ds-tip {
      color: #9b9b9b;
      font-size: 12px;
      margin-top: 16px;
      margin-bottom: 35px;
    }
    & .input-tip {
      color: #9b9b9b;
      font-size: 12px;
      margin-top: 4px;
    }
    & .error-msg {
      font-size: 12px;
      color: #f5222d;
      margin-top: 4px;
    }
  }

  @b message {
    margin-bottom: 1.5rem;
    white-space: normal;

    & > a {
      color: var(--accent-color);
    }
  }

  @b row_button {
    display: block !important;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  @b collection {
    margin-bottom: 1.25rem;

    & p {
      margin-bottom: 1.25rem;
    }
  }

  @b table {
    margin-left: -2.5rem;
    width: calc(100% + 5rem);
    margin-bottom: 0.5rem;

    @e grey_text {
      color: var(--grey-border-color);
      text-transform: uppercase;
      font-weight: bold;
    }

    @e type {
      color: var(--grey-border-color);
    }

    @e channel {
      color: var(--text-dark-color);
      font-weight: bold;
    }

    @e arrow {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      vertical-align: text-bottom;
    }
  }

  @b table {
    & th:first-child {
      padding-left: 2.5rem !important;
    }

    & td:first-child {
      padding-left: 2.5rem !important;
    }

    & .register-collection-input {
      padding: 5px !important;
      width: 140px !important;
    }
  }

  @b location {
    border: 1px solid var(--layout-border-color);
    border-radius: 4px;
    margin: 24px 0;
    padding: 8px 16px;
    height: 210px;
    overflow: auto;

    @e name {
      margin-left: 16px;
    }

    & .ant-checkbox-inner {
      border-radius: 50%;
    }

    & > li {
      padding: 8px 0;
    }
  }

  @b add_location {
    @e cloud {
      width: 24px;
      margin-right: 16px;
      position: relative;
      top: 4px;
      @s detail path {
        fill: var(--accent-color);
      }
    }

    @e remove {
      font-size: 24px !important;
      margin-right: 16px;
      position: relative;
      top: 4px;
      color: var(--danger-color);
    }

    @e cloud path {
      fill: var(--grey-border-color);
    }

    @e img {
      @s background .ant-upload {
        height: 94px;
      }

      @s logo .ant-upload {
        height: 70px;
      }
    }
    @e img {
      & .ant-upload {
        background-size: cover;
        background-position: center center;
        padding: 0;
      }

      & .ant-upload-drag-container {
        background-color: var(--layer-background-color);
      }
    }

    @e category {
      width: 120px;
      float: left;
    }

    @e outdoor {
      margin-left: 128px;
    }

    @e group {
      margin-bottom: 24px;
    }

    @e icon {
      font-size: 12px !important;
      vertical-align: middle !important;
      margin-left: 8px;
      color: var(--accent-color);
    }
  }

  @b add_location {
    & .ant-form-vertical .ant-form-item {
      padding-bottom: 0;
    }

    & .ant-form-item-required:before {
      color: var(--accent-color);
      font-weight: bold;
    }
  }

  @b no_result {
    text-align: center;
    color: #999;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

.see-more-brands-link {
  text-decoration: underline;
}