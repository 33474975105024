.JPMC_background {
  width: 100vw;
  height: 100vh;
  background: url("../../images/kiosk/backgroundJPMC.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 80;
}
.JPMC_title {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  z-index: 6;
  padding: 2.3vh 2vw 0vh 3vw;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 4.1vh;
  line-height: 2.8vh;
}

.JPMC_container_floor {
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 4vh;
  line-height: 4.5vh;
  display: flex;
  padding: 3.5vh 2vw 0vh 3vw;
}
.JPMC_container_top {
  z-index: 2;
  display: flex;
  padding: 2vh 2vw 1.5vh 3vw;
  flex-direction: row;
  justify-content: space-between;
}

.JPMC_container_dates {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.JPMC_dates_indicators_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6vh 0 0.6vh 0;
}
.JPMC_dates_normal {
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 3.2vh;
  line-height: 3vh;
}
.JPMC_exponent {
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 1.8vh;
}
.JPMC_dates_normal_bottom {
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 4.4vh;
  line-height: 4.4vh;
}
.JPMC_dates_small {
  padding: 1vh 3vw 0.5vh 0;
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 3vh;
  line-height: 3vh;
  @media (max-width: 350px) {
    font-size: 2.6vh;
  }
}
.JPMC_dates_small_bottom {
  margin: 0.7vh 0 0 0;
  z-index: 2;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 3vh;
  line-height: 3vh;
}

.JPMC_container_officeFloorPlan {
  display: flex;
  justify-content: center;
  height: 42vh;
  width: 30vh;
  margin: 0 auto;
  z-index: 2;
}
.JPMC_container_bottom {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 3vh 4vw 2vh 3vw;
}
.average_indicators {
  width: 90%;
  margin: 1.6vh 0 0 15vw;
}

.average_indicators > div > p {
  width: 50%;
}
