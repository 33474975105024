.spinner-dot {
  display: inline-block;
}

.spinner-dot > div {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dot .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background-color: #8eccde;
}

.spinner-dot .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: #0b9fbf;
}

.spinner-dot .bounce3 {
  background-color: var(--accent-color);
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
