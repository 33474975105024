

@component-namespace changelog {
  @b container {
    padding-left: 100px;

    & .ant-timeline-item {
      padding-bottom: 40px;
    }

    & .ant-timeline-item-content {
      margin-left: 40px;
    }
  }

  @b content {
    & ul {
      padding-left: 40px;
      list-style: circle;
    }

    & p {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  @b title {
    font-weight: bold;
    font-size: 24px;
    font-famaily: 'Nunito';
  }

  @b date {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
