

@component-namespace welcome-modal {
  @b wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  @b mask {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    padding: 70px 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: rgba(0, 0, 0, 0.3);
  }
  @b body {
    position: relative;
    z-index: 1001;
    text-align: center;
    width: 880px;
    padding: 30px 50px;
    /* top: 70px; */
    margin: 0 auto;
    background: white;
  }
  @b body img {
    width: 90%;
    margin: 0 auto;
  }
  @b body .slick-dots li {
    background: #cacaca;
  }
  @b body .ant-carousel .slick-dots li.slick-active button {
    background: var(--accent-color);
  }

  @b nav {
    position: absolute;
    top: 50%;
    font-size: 40px;
    margin-top: -20px;
  }

  @b desc {
    font-size: 14px;
    color: var(--text-dark-color);
    margin-top: 25px;
  }

  @b body .nav-left {
    left: 10px;
  }
  @b body .nav-right {
    right: 10px;
  }

  @b body .start-v3-btn {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  @b body .start-v3-btn button {
    width: 200px;
  }
}
