@keyframes bounce {
  0%,
  100% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
}

.Q-loading {
  & .ant-spin {
    height: 100vh !important;
    max-height: none !important;
    animation-fill-mode: both;
    animation: bounce 2s infinite ease-in-out;
  }

  & .ant-spin-blur {
    height: 100vh;
  }
}
