@component-namespace custom {
  @b select {
    line-height: 1 !important;
    & > .ant-select-selection--multiple {
      min-width: 200px;
    }
    @m hideIcon {
      & .ant-select-arrow {
        display: none;
      }

      & .ant-select-selection__rendered .ant-select-selection-selected-value {
        padding-left: 0;
        padding-right: 0;
      }

      & .ant-select-selection__rendered {
        margin: 0;
      }
    }

    @m underline {
      color: var(--accent-color);
      /* line-height: inherit !important; */
      border-bottom: 1px solid var(--accent-color);

      & .ant-select-dropdown-menu-item {
        font-family: Dosis;
        background: #f9fdff;
      }

      & .ant-select-selection__placeholder {
        top: 30%;
      }

      & .ant-select-selection__rendered {
        line-height: inherit;
        padding-bottom: 0.5rem;
        margin-left: 0;
      }

      & .ant-select-selection {
        height: auto;
        border: none;
        font-weight: 500;
        font-family: Dosis;
        font-size: 1.1rem;

        &:active,
        &:focus {
          box-shadow: none;
        }
      }

      &.ant-select-focused .ant-select-selection {
        box-shadow: none;
      }
    }

    @m dropdown {
      & .ant-select-dropdown-menu-item:hover {
        background-color: #f6fafc;
      }

      &.text-center {
        text-align: center;
      }

      & .ant-select-dropdown-menu-item-selected {
        font-weight: 500;
        color: #207ea2;

        &:hover {
          font-weight: 500;
          color: #207ea2;
        }
      }

      & ul {
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: var(--white-background-color);
        }

        &::-webkit-scrollbar-thumb {
          background: #15789e;
          border-radius: 5px;
        }
      }
    }
  }

  @b select {
    & + .custom-select {
      margin-left: 15px;
    }

    @s block {
      width: 100%;
    }

    &.ant-select-disabled {
      color: rgba(0, 0, 0, 0.65);

      & .ant-select-selection {
        background: none;
        border: none;
      }

      & .ant-select-selection__choice {
        color: rgba(0, 0, 0, 0.65) !important;
        background-color: #fafafa !important;
      }
    }

    @s block {
      width: 100%;
    }
  }
}

.ant-form-item-control.has-error .custom-select--underline {
  color: var(--danger-color);
  /* line-height: inherit !important; */
  border-bottom: 1px solid var(--danger-color);
}
