@component-namespace collection {
  @b selector {
    width: 100%;
    text-align: center;
    display: block;

    @m dropdown {
      position: relative;
      width: 100%;
      text-align: center;

      & .ant-select-dropdown-menu-item {
        background-color: var(--accent-color) !important;
        color: var(--text-white-color) !important;
        border-radius: 0;
      }
      & .ant-select-dropdown-menu-item:hover {
        background-color: #bed1d4 !important;
      }

      & .ant-select-dropdown-menu-item-selected {
        display: none;
      }
    }

    @m stale {
      display: inline-block;
      background-color: var(--danger-color);
      padding: 3px 6px;
      color: var(--text-white-color);
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      top: 5px;
      left: 8px;
      border-radius: 5px;
      line-height: 1.4;
    }
  }

  @b selector {
    @m dropdown {
      background: transparent !important;
    }
    & .ant-select-selection-selected-value {
      float: none;
      padding-right: 0;

      & .collection-selector--stale {
        left: -2px;
        font-size: 1.2vh;
        line-height: 1.8vh;
      }
      & .collection-selector--name {
        padding-left: 2vw;
        font-size: 1.8vh;
        line-height: 1.8vh;
      }
    }

    & .ant-select-selection__rendered:after {
      display: none;
    }

    & .ant-select-selection {
      background-color: var(--accent-color);
      border-radius: 0;
      border: none;
      color: var(--text-white-color);
      box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24),
        0 1.5px 6px rgba(0, 0, 0, 0.12);
    }
  }
}
