@component-namespace billing {
  @b menu-title {
    font-size: 14px;
    color: #3388a9;
    font-weight: bold;
  }

  @b container {
    padding: 20px 0px;

    & .header-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .alert-danger {
      color: white;
      background: var(--danger-color);
      padding: 5px 14px;
      cursor: pointer;
    }

    & .ant-tabs {
      padding: 0 15px;
      overflow: auto;
      padding-bottom: 20px;
    }
    & .org-name {
      padding: 0 15px;
      margin-bottom: 20px;
    }
    & .org-name-label {
      color: var(--grey-border-color);
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .org-name-value {
      color: var(--text-dark-color);
      font-size: 30px;
      font-weight: 700;
    }

    & .ant-tabs-nav .ant-tabs-tab {
      padding-left: 0;
      padding-right: 0;
    }

    & .go-back .title {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    & .go-back .iconfont {
      color: var(--accent-color);
      margin-right: 15px;
      font-size: 24px;
      cursor: pointer;
    }
  }

  @b tab-summary {
    display: flex;
    margin: 20px 0;
    & .panel {
      flex: 1;
      padding: 25px;
      text-align: center;
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);

      & .label {
        color: var(--grey-border-color);
        font-size: 12px;
      }

      & .value {
        color: var(--text-dark-color);
        font-size: 24px;
        font-weight: 700;
        font-family: Dosis;
      }
    }

    & .panel + .panel {
      margin-left: 15px;
    }
  }
}

.header-notification-overlay.is-menu.billing-menu .ant-popover-inner {
  width: 200px;
}

.location-filter .remove-user {
  color: var(--danger-color);
  background: var(--secondary-background-light-mobile);
  font-weight: bold;
  line-height: 1rem;

  &:hover {
    color: var(--danger-color);
  }
}
@component-namespace payment-tip {
  @b modal {
    & .ant-modal-body {
      text-align: center;
      border-top: 10px solid var(--danger-color);
      padding: 40px 100px 60px 100px;
    }

    & .title {
      color: var(--danger-color);
      font-size: 42px;
    }
    & .title h1 {
      font-size: 36px;
      color: var(--danger-color);
      margin-top: 20px;
      font-weight: 700;
      font-family: Nunito;
    }
    & .content {
      font-size: 18px;
      color: var(--text-dark-color);
      line-height: 32px;
      margin: 40px 0px;
    }
    & .content em {
      font-weight: bold;
      color: var(--danger-color);
    }
    & .unpay-invoice {
      display: inline-block;
      border: 1px solid var(--danger-color);
      border-radius: 20px;
      padding: 5px 15px;
      color: var(--danger-color);
      cursor: pointer;
      margin: 16px 10px;
    }
  }
}
