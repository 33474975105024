@component-namespace location_detail {
  @b favorite-btn {
    width: 150px;
    height: 28px;
  }
  @b top-action {
    margin-bottom: 16px;
    & .header-btn-item {
      display: inline-block;
      line-height: 20px;
      font-size: 15px;
      text-align: center;
      height: 29px;
      padding: 4px 12px;
      background: var(--secondary-background-light-mobile);
      border-radius: 2px;
      margin-left: 15px;
      cursor: pointer;
      transition: 0.3s all ease;
      color: #4A4A4A;
      /* flex: 1; */
      &:hover {
        color: var(--text-white-color);
        background: var(--accent-color);
      }

      & i {
        margin-right: 8px;
      }
    }
  }
  @b integration {
    @e box {
      padding: 12px 10px;
      border: 1px dashed #cacaca;
      border-radius: 4px;
      & img {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 20px;
      }

      & img:nth-last-child(1) {
        margin-bottom: 0;
      }

      & p {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        line-height: 17px;
      }
    }
    @e container {
      margin-top: 24px;
      /* padding-bottom: 56px; */
    }

    @e checkbox {
      float: left;
      margin-right: 8px !important;
    }

    @e reset_button {
      float: right;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }

    @e unbind {
      text-decoration: underline;
      &:hover {
        color: var(--danger-color);
      }
    }

    @e button {
      display: flex;
      margin-top: 56px;
      text-align: center;
      justify-content: space-between;
      & .custom-button {
        min-width: 173px;
      }
    }

    @e success {
      text-align: center;
      margin-top: 10px;
    }

    @e success_message {
      color: var(--text-dark-color);
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC;
    }

    @e list_card {
      margin-bottom: 16px;
      cursor: pointer;
      padding: 0 16px;
      height: 67px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid rgba(236, 236, 236, 1);
      border-radius: 8px;
      box-shadow: 0px 6px 9px 2px rgba(218, 218, 218, 0.5);
      position: relative;
    }

    @e list_card .status-icon {
      position: absolute;
      right: 16px;
    }

    @e reset {
      width: 35px;
      margin-right: 20px;
    }

    @e arc {
      height: 30px;
      margin-right: 10px;
    }

    @e name {
      line-height: 24px;
      font-size: 18px;
      font-family: Nunito;
      font-weight: bold;
      line-height: 24px;
    }

    @e title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 20px 0;
      border-bottom: 1px solid #efefef;
    }

    @e back {
      font-size: 40px !important;
      position: absolute;
      left: 0;
      bottom: 17px;
    }
  }
  @b header {
    margin: 24px 0 16px 0;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    @e arrow {
      font-size: 24px;
      line-height: 31px;
      vertical-align: middle;
      cursor: default;
    }

    /* @e breadcrumb {
      width: 320px;
    } */

    @e setting {
      /* float: right; */
      /* width: 485px; */
      /* display: flex; */
      margin-bottom: 24px;
      color: var(--text-dark-color);
      /* text-align: right; */
    }

  }

  @b breadcrumb {
    font-size: 24px;
    font-family: Dosis;
    line-height: 31px;
    user-select: none;
    @s active {
      font-family: Dosis;
      color: var(--text-dark-color) !important;
      font-weight: 500;
      cursor: default;

      &:hover {
        color: var(--text-dark-color);
      }
    }
  }

  @b content {
    padding-bottom: 240px;
    padding-left: 1px;
    padding-top: 1px;
    overflow: hidden;
    /* display: flex; */
    /* justify-content: flex-start; */
  }
  @b left {
    width: 320px;
    float: left;
    background: var(--white-background-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    @e done {
      /* position: relative; */
      display: flex;
      align-items: center;
      margin: 24px;

      & i {
        /* position: relative; */
        margin-left: 5px;
        margin-right: 0;
        font-size: 12px;
      }

      & .ant-btn {
        border-radius: 2px;
        min-width: 70px;
        margin-right: 8px;
      }
    }

    @e follow {
      font-family: Nunito;
      font-weight: bold;
      /* font-size: 16px; */
      color: var(--text-dark-color);
      &:hover {
        color: var(--accent-color);
      }

      & i {
        margin-left: 0;
        margin-right: 17px;
      }
    }

    @e img {
      width: 100%;
      height: 195px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    @e row {
      margin: 24px 24px 0 24px;

      &:last-child {
        padding-bottom: 10px;
      }
    }

    @e label {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }

    @e edit {
      cursor: pointer;

      & > i {
        font-size: 12px;
        margin-left: 8px;
      }
    }

    @e content {
      padding-left: 20px;

      & > i {
        font-size: 15px;
        color: var(--text-dark-color);
        float: left;
        margin-top: 5px;
        margin-left: -20px;
      }
    }

    @e text {
      /* font-size: 15px; */
      padding: 4px 11px;
      font-family: Nunito;
      color: var(--text-dark-color);
      /* line-height: 20px; */
      resize: none;
      background: none;
      border: none;

      &.ant-input-disabled {
        background: none;
        border: none;
        cursor: default;
      }
    }

    @e progress {
      padding: 0 5px;
    }

    @e upload {
      position: relative;
      height: 200px;
      & .ant-upload.ant-upload-drag {
        padding: 0;
        border: none;
        border-radius: 0;

        & .ant-upload {
          padding: 0;
        }
      }

      & .ant-upload {
        background-size: cover !important;
        position: relative;
      }

      & .ant-upload-text {
        color: var(--accent-color) !important;
        font-weight: bold;
        cursor: pointer;
        &.danger_text {
          color: var(--danger-color) !important;
        }
      }
    }

    @e upload_layer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: var(--base-transition);
      background-color: var(--layer-background-color);
      &:hover {
        opacity: 1;
      }
    }
  }

  @b right {
    padding-left: 360px;
    padding-right: 2px;

    @e welcome {
      padding: 24px 24px 28px 40px;
      margin-bottom: 24px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

      & h2 {
        font-family: "Nunito";
        font-size: 30px;
        line-height: 41px;
        margin-bottom: 24px;
        color: var(--text-dark-color);
      }

      & p {
        font-family: "Nunito";
        font-size: 14px;
        padding-right: 100px;
        line-height: 19px;
        margin-bottom: 24px;
        color: var(--text-dark-color);
      }

      & a {
        font-family: "Nunito";
        font-size: 15px;
        font-weigth: bold;
        color: var(--accent-color);
        line-height: 20px;
        font-style: italic;
      }
    }
  }
  @b collection-more-absolute {
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 20px;
  }
  @b average-more {
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 20px;
  }

  @b collection {
    background: var(--primary-color);
    margin-bottom: 24px;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    @e name {
      padding: 10px 16px 10px 25px;
      font-size: 15px;
      position: relative;
      line-height: 20px;
      font-weight: bold;
      color: var(--text-dark-color);
      min-height: 54px;

      white-space: nowrap;

      & > p {
        color: var(--grey-border-color);
        font-weight: normal;
        margin-top: 2px;
      }
    }

    @e status {
      display: block;
      width: 8px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--status-offline-color);
      @s online {
        background-color: var(--status-online-color);
      }
      @s good {
        background-color: var(--status-good-color);
      }
      @s hazardous {
        background-color: var(--status-hazardous-color);
      }
      @s moderate {
        background-color: var(--status-moderate-color);
      }
      @s sensitive {
        background-color: var(--status-sensitive-color);
      }
      @s unhealthy {
        background-color: var(--status-unhealthy-color);
      }
      @s very-unhealthy {
        background-color: var(--status-very-unhealthy-color);
      }
      @s excellent {
        background-color: var(--status-excellent-color);
      }
    }

    @e reading {
      padding: 12px 0 6px 25px;
      border-top: 1px solid var(--layout-border-color);
    }

    @e type {
      margin-bottom: 8px;
    }

    @e line {
      cursor: default;
    }

    @e line > .indicator-unit {
      line-height: 22px;
      vertical-align: bottom;
    }

    @e value {
      font-size: 20px;
      line-height: 27px;
      vertical-align: middle;
      font-weight: bold;
    }

    @e list.flex {
      display: flex;
      flex-wrap: wrap;
    }

    @e list > li {
      /* width: 110px; */
      flex: 1 1 110px;
      margin-bottom: 6px;

      & span:first-child {
        margin-right: 4px;
      }
    }

    @e indicator_status {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: var(--status-offline-color);
      @s online {
        background: var(--status-online-color);
      }
      @s good {
        background: var(--status-good-color);
      }
      @s hazardous {
        background: var(--status-hazardous-color);
      }
      @s moderate {
        background: var(--status-moderate-color);
      }
      @s sensitive {
        background: var(--status-sensitive-color);
      }
      @s unhealthy {
        background: var(--status-unhealthy-color);
      }
      @s very-unhealthy {
        background: var(--status-very-unhealthy-color);
      }
      @s excellent {
        background: var(--status-excellent-color);
      }
    }

    @e indicator_menu {
      border-top: 1px solid var(--status-good-color);
      /* display: inline; */
      width: 78px;
      padding-top: 8px;
      padding-bottom: 8px;

      & > .ant-dropdown-menu-item {
        padding: 5px 0;
        &:hover {
          background: none;
        }
      }
    }

    @e indicator_menu_item {
      text-align: center;

      & > div {
        width: 28px;
        height: 28px;
        margin: auto;
        background: var(--secondary-background-light-mobile);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease background;
      }

      & path {
        transition: 0.3s ease fill;
        fill: var(--text-dark-color);
      }

      & svg {
        width: 18px;
      }

      &:hover {
        & > div {
          background: var(--accent-color);
        }

        & path {
          fill: var(--text-white-color);
        }

        & .material-icons {
          color: var(--text-white-color);
        }

        & .small_text {
          color: var(--text-dark-color);
        }
      }
    }

    @e more {
      float: right;
      margin-top: 2px;
      line-height: 20px;
    }

    @e circle {
      & .ant-progress-text {
        font-size: 18px;
        font-weight: bold;
        font-family: Nunito;
        /* background: var(--white-background-color); */
        margin-top: -5px !important;
      }

      @s online .ant-progress-circle-path {
        stroke: var(--status-online-color) !important;
      }
      @s good .ant-progress-circle-path {
        stroke: var(--status-good-color) !important;
      }
      @s hazardous .ant-progress-circle-path {
        stroke: var(--status-hazardous-color) !important;
      }
      @s moderate .ant-progress-circle-path {
        stroke: var(--status-moderate-color) !important;
      }
      @s sensitive .ant-progress-circle-path {
        stroke: var(--status-sensitive-color) !important;
      }
      @s unhealthy .ant-progress-circle-path {
        stroke: var(--status-unhealthy-color) !important;
      }
      @s very-unhealthy .ant-progress-circle-path {
        stroke: var(--status-very-unhealthy-color) !important;
      }
      @s excellent .ant-progress-circle-path {
        stroke: var(--status-excellent-color) !important;
      }
    }

    @e circle_container {
      position: relative;
      display: inline-block;
      margin: 6px 28px 6px 0;
    }

    @e circle_name {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 10px;
      white-space: nowrap;
    }

    @e progress_container {
      display: flex;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
    }

    @e progress_name {
      flex: 0 0 50px;
      text-align: right;
      margin-right: 16px;
      overflow: hidden;
    }

    /* @e progress_unit {
      width: 50px;
    } */

    @e progress_value {
      width: 130px;
      margin-left: 16px;
      font-size: 18px;
      font-family: Nunito;
      font-weight: bold;
    }
  }

  @b setting {
    @e part {
      padding: 12px 0;

      & > h4 {
        font-size: 14px;
        font-family: Nunito;
        font-weight: bold;
        /* color: #5c5c5c; */
        line-height: 19px;
        margin-bottom: 8px !important;
      }

      & > p {
        font-size: 12px;
        font-family: Nunito;
        /* color: var(--text-dark-color); */
        line-height: 16px;
      }
    }
    @e row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 24px;

      @s working_hours {
        margin-bottom: 16px;
      }
    }

    @e row_left {
      width: 260px;

      & > h4 {
        font-size: 14px;
        line-height: 19px;
        color: var(--text-grey-color);
        font-weight: bold;
      }

      & > p {
        color: var(--text-grey-color);
        margin-bottom: 0;
      }
    }

    @e row_right {
      width: 130px;
      text-align: right;

      & .ant-select {
        width: 100%;
      }
    }

    @e working_hours {
      width: 100%;
      background: red;
      margin-bottom: 32px;
    }

    @e button {
      text-align: center;

      & .custom-button {
        min-width: 173px;
      }
    }

    @e reset_list {
      padding: 0 8px 8px 8px;

      & > li {
        display: flex;
        font-size: 12px;
        line-height: 16px;
        color: var(--text-grey-color);
        margin-top: 8px;

        &:first-child {
          color: var(--text-dark-color);
          font-weight: bold;
        }

        & > div {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @b working_hours {
    width: 100%;
    margin-bottom: 32px;
    @e days {
      text-align: center;
    }

    @e day_wrapper {
      margin: 0 8px;
      cursor: pointer;
    }

    @e day {
      color: var(--text-grey-color);
      width: 26px;
      height: 26px;
      font-weight: bold;
      transition: var(--base-transition);

      &:hover {
        color: var(--primary-color);
        background: var(--accent-color);
      }

      & > * {
        line-height: 26px;
      }

      @s select {
        color: var(--primary-color);
        background: var(--accent-color);
      }
    }

    @e row {
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      & > span {
        display: inline-block;
      }
      @s select {
        margin: 16px 0;
      }
    }

    @e date {
      width: 90px;
      color: var(--text-grey-color);
      padding: 0 10px;
    }

    @e time {
      width: 105px;

      & > .ant-select {
        width: 100%;
      }

      @s time {
        text-align: right;
        font-weight: bold;
        padding-right: 30px;
      }
    }

    @e to {
      width: 28px;
      text-align: center;
    }

    @e icon {
      flex: 1;
      text-align: center;

      & > .anticon {
        cursor: pointer;
        transition: var(--base-transition);
        &:hover {
          color: var(--accent-color);
        }
      }
    }
  }

  @b collection_table {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @b limit {
    @e add_text {
      margin-left: 0 !important;
      margin-top: 10 !important;
      display: inline-block !important;
    }
    @e row {
      margin: 5px 0;
    }
    @e type {
      display: inline-block;
      width: 135px;
      margin-right: 25px;
    }

    @e type_dropdown {
      width: 135px;
    }
    @e indicator {
      display: inline-block;
      width: 100px;
      margin-right: 25px;
    }

    @e indicator_dropdown {
      width: 65px;
    }
    @e limit {
      display: inline-block;
      width: 100px;
    }
    @e value {
      display: inline-block;
      width: 70px;
      margin-right: 25px;
    }
    @e delete {
      display: inline-block;

      & > a {
        font-size: 20px;
        color: var(--text-grey-color);
        position: relative;
        top: 5px;

        &:hover {
          color: var(--danger-color);
        }
      }
    }

    @e add_text {
      font-weight: bolder;
      font-size: 16px;
      line-height: 22px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  @b stale {
    font-size: 12px;
    color: var(--text-white-color);
    padding: 0px 6px;
    background: var(--danger-color);
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    white-space: nowrap;
    display: inline-block;
  }

  @b name {
    display: inline-block;
    width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-dark-color);
  }

  @b floating_menu {
    float: right;
    /* clear: both; */
  }

  @media (--only-mobile) {
    .location_detail-header, .location_detail-top-action {
      display: none;
    }

    @b left {
      display: none;
    }
    @b right {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1.5rem;
    }
    @b content {
      padding-bottom: 0px;
    }
  }
}

.link-icon {
  & svg {
    vertical-align: middle;
    width: 18px;
    height: auto;
  }
  & span {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }
  & .iconfont {
    vertical-align: middle;
  }
}

.notification-icon-primary path {
  fill: var(--accent-color);
}

.edit-information-row {
  margin-bottom: 16px;
}

.edit-information-label {
  margin-bottom: 8px;
}
.edit-information-label i {
  vertical-align: middle;
  margin-right: 8px;
}
.edit-information-content {
  padding-left: 24px;
}

.order-indicator-list .indicator-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
}
.order-indicator-list .indicator-item .order-action span {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.order-indicator-list .indicator-item .order-action i {
  font-size: 18px;
}
