

@component-namespace custom {
  @b button {
    @m round.ant-btn {
      border-radius: 5px;
    }

    @m rect.ant-btn {
      border-radius: 2px;
    }

    @m light.ant-btn {
      font-size: 1rem;
      font-family: Nunito;
      font-weight: bold;
      background: var(--primary-color) !important;
    }

    @m dark.ant-btn {
      font-size: 1rem;
      font-weight: bold;
      font-family: Nunito;
    }

    @m block {
      &.ant-btn {
        display: block;
        width: 100%;
      }

      &.ant-btn + .ant-btn {
        margin-top: 10px;
      }
    }

    @m danger {
      &.ant-btn {
        background-color: var(--danger-color);
        border-color: var(--danger-color);
        color: var(--text-white-color);

        &:hover,
        &:active,
        &:focus {
          background-color: var(--danger-color);
          border-color: var(--danger-color);
        }
      }
    }
  }
}
