@component-namespace report-pr {
  @b title {
    font-size: 16px;
    font-family: Dosis;
    font-weight: bold;
    padding: 0px 50px;
    line-height: 20px;
  }

  @b print {
    font-size: 1.5rem;
    font-family: Dosis;
    display: flex;
    width: var(--desktop-min-width);
    margin: 0 auto;
    justify-content: space-between;
    padding: 30px 100px;
    align-items: center;

    & p {
      margin-bottom: 0;
    }
  }

  @b wrapper {
    box-shadow: 0px 2px 7px 0px rgba(0, 107, 148, 0.18);
    margin-bottom: 10px;
  }

  @b line {
    height: 0px;
    border-bottom: 2px solid #3388a9;
    width: 40px;
    margin: 24px 0;
  }
  @b header {
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-family: Dosis;
    display: flex;
    justify-content: space-between;
  }

  @b content {
    padding: 0;
  }

  @b panel-footer {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: 0;
  }

  @b header-for-print {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    margin: 0 auto;
  }

  @b print-logo {
    align-items: center;
    display: flex;

    & > img {
      height: 20px;
    }
  }

  @b print-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @b print-location {
    font-family: Dosis;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-grey-color);
    margin-right: 16px;
  }

  @b print-date {
    color: var(--text-grey-color);
    text-align: right;
    font-family: Nunito;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 0 4px 16px;
    border-left: 2px solid #3388a9;
  }

  @b panel {
    page-break-after: always;
    page-break-inside: avoid;
    width: 200mm;
    background-color: var(--white-background-color);
    margin: 0 auto;
    height: 287mm;
    min-height: 28.7cm;
    position: relative;
  }

  @b summary {
    border: none;
    padding: 20px 0 20px 70px;
    margin-bottom: 0px;
    display: flex;
  }

  @b summary-unit {
    width: 25%;
    padding: 0;

    & + div {
      border-left: none;
      padding: 0;
    }
  }

  @b summary-unit-content {
    position: relative;
    display: flex;
  }

  @b summary-unit-label {
    font-size: 12px;
    color: var(--grey-border-color);
  }

  @b panel-header {
    margin: 0 20px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
  }

  @b panel-title {
    font-size: 14pt;
    font-weight: 600;
    color: var(--text-dark-color);
    font-family: Dosis;
  }

  @b v-primary {
    font-size: 30px;
    color: var(--text-dark-color);
    line-height: 1.2;
    font-family: Dosis;
    font-weight: 600;
  }

  @b v-second {
    font-size: 12px;
    color: var(--grey-border-color);
    padding-right: 2px;
  }

  @b v-left {
    text-align: right;
    line-height: 1;
  }

  @b v-right {
    margin-left: 10px;
    line-height: 1.42;
    align-self: flex-end;
  }

  @b v-top {
    position: relative;
    display: flex;

    & .report-v-badge {
      margin-top: 5px;
    }

    & .report-v-primary {
      line-height: 1;
    }
  }

  @b v-bot {
    font-size: 12px;
    color: var(--grey-border-color);
    line-height: 1.1;
  }

  @b v-bot-desc {
    margin-left: 6px;
  }

  @b v-badge {
    background-color: #d0021b;
    text-align: center;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;

    & > span {
      font-size: 12px;
      color: var(--text-white-color);
      display: block;
      transform: scale(0.8);
    }
  }

  @b v-desc {
    color: var(--grey-border-color);
    font-size: 12px;
    line-height: 1;
    margin-top: 3px;
  }

  @b v-unit {
    font-size: 12px;
    color: var(--text-dark-color);
  }

  @b bar-title {
    color: var(--text-dark-color);
    padding: 20px 47px;
    font-family: Dosis;
    font-size: 16px;
    margin-top: 0;
    padding-bottom: 10px;
  }

  @b bar-title-sub {
    font-size: 24px;
    color: var(--grey-border-color);
    margin-left: 10px;
    font-family: Dosis;
  }

  @b bar-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 47px 0px 70px;
    font-family: Nunito;
    align-items: center;
  }

  @b bar-legend {
    font-size: 12px;

    & span {
      border-left: 5px solid;
      padding-left: 8px;
    }

    & span + span {
      margin-left: 20px;
    }
  }

  @b filter {
    font-size: 10px;
  }
  @b filter-tag {
    padding: 2px 7px;
    margin-left: 8px;
    display: inline-block;
    color: var(--text-dark-color);
    background-color: rgba(1, 119, 157, 0.1);
  }

  @b toolbar {
    margin-bottom: 15px;
  }
}
@media print {
  @page {
    margin: 0.5cm;
    size: A4;
  }
  html,
  body {
    margin: 0 auto;
    width: 200mm;
    height: 287mm;
  }
  .drive-line {
    width: 40px;
    margin-top: 15px;
    margin-left: 50px;
    margin-right: 10px;
    border-bottom: 1px solid #3388a9;
  }
  .drive-line.center {
    margin: 0 auto;
    margin-top: 20px;
  }
  .print-only {
    display: block;
  }

  .print-hidden {
    display: none;
  }
  @component-namespace container {
    @b wrapper {
      padding: 0;
    }
  }
  @component-namespace header {
    @b container {
      display: none;
    }
  }
  @component-namespace navbar {
    @b container {
      display: none;
    }
  }
}
