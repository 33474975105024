
@import './common.css';

@component-namespace ds {
  @b table-container {
    border-left: 1px solid var(--layout-border-color);
    border-right: 1px solid var(--layout-border-color);

    &
      .ant-table-small
      > .ant-table-content
      > .ant-table-body
      > table
      tr
      > .td-1 {
      padding-left: 20px;
    }
  }

  @b table-label {
    & svg {
      height: 14px;
      vertical-align: middle;
    }

    & span {
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  @b datasource-item {
    cursor: pointer;
  }

  @b table-header {
    display: flex;
    padding-top: 6px;
    font-size: 12px;
    justify-content: space-between;
  }

  @b filter {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;

    & svg {
      margin-right: 5px;
    }
  }
  @media (--only-mobile) {
    colgroup {
      display: flex;
      flex-direction: column;
    }
    @b table-header {
      padding: 0.375rem 1rem;
    }
  }
}

.uuid {
  color: #999;
  margin-left: 8px;
}
