.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  cursor: default;
  font-size: 1rem; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  cursor: pointer;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 1.125rem;
}
.material-icons.md-24 {
  font-size: 1.5rem;
}
.material-icons.md-30 {
  font-size: 1.875rem;
}
.material-icons.md-36 {
  font-size: 2.25rem;
}
.material-icons.md-48 {
  font-size: 3rem;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
