.chartjs-html-legend {
  max-height: 100px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 10px;
}

.chartjs-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.chartjs-legend li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 20px;
  font-size: 12px;
}

.chartjs-legend span {
  display: inline-block;
  width: 20px;
  height: 10px;
  margin-right: 10px;
}
