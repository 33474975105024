

@component-namespace indicator {
  @b message {
    margin-top: 14px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  @b button .ant-btn {
    border-radius: 2px;
    min-width: 64px;
  }

  @b search {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  @b btn.ant-btn {
    height: 30px;
    font-size: 12px;
    text-align: left;
  }

  @b label {
    margin-bottom: 0;
  }

  @b value {
    margin-bottom: 8px;
  }

  @b left_space {
    padding-left: 10px;
  }

  @b form {
    margin: 8px 0;
  }

  @b name {
    font-weight: normal !important;
  }

  @b row {
    vertical-align: top;
  }

  @b tr {
    padding: 16px 0;
  }

  @b arrow {
    text-align: center;
  }

  @b row .indicator-arrow {
    margin-top: 24px;
  }

  /* @b table .register-collection-input {
    padding: 5px !important;
    width: 140px !important;
  } */

  @b table {
    margin-bottom: 0.5rem;
  }

  @b checkbox_container {
    text-align: center;
    padding: 5px 0;

    & .ant-checkbox-inner {
      border-radius: 50%;
    }
  }

  @b body {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @b rename {
    @e input {
      width: 133px;
      margin-bottom: 8px;
    }
  }

  @b location {
    color: var(--accent-color);
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
