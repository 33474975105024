@component-namespace graph {
  @b header {
    font-size: 1.1rem;
    margin-bottom: 30px;
    /* padding-top: 30px; */
    font-family: Dosis;
    display: flex;
    /* justify-content: space-between; */
    & > span {
      padding-top: 30px;
    }
  }

  @b tips {
    margin-top: -15px;
    margin-bottom: 15px;
  }

  @b content {
    padding-top: 2rem;
  }

  @b location {
    min-width: 200px;
  }
  @b empty-tip {
    text-align: center;
    padding: 50px;
  }

  @b colletions {
    margin-left: 15px;
  }

  @b header-selector {
    margin-bottom: 10px;
    & > .group {
      display: inline-block;
      margin-right: 30px;
    }

    & > .block {
      margin-top: 20px;
    }

    & .label {
      margin-bottom: 5px;
      color: var(--accent-color);
      font-size: 14px;
      font-family: Nunito;
    }

    & .graph-refer {
      max-width: 400px;
    }
    & .graph-timezone {
      width: 300px;
    }
  }

  @b dropdown {
    position: relative;
    margin: 0 8px;
    min-width: 3em;
    margin-top: 30px;
    @e label {
      font-size: 0.5rem;
      position: absolute;
      top: -24px;
    }
    @e item {
      color: var(--accent-color);
      font-size: 1.1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--accent-color);
    }

    @e indicator {
      min-width: 40px;
    }

    @e yaxis {
      width: 50px;

      @s error {
        border-bottom-color: var(--danger-color) !important;
        color: var(--danger-color);
      }
      /* text-align: center; */
    }

    & .ant-input {
      border: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none !important;
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--accent-color);
    }
  }

  @b panel {
    border-radius: 2px;
    width: 100%;
    padding: 30px 0;
    position: relative;
    background-color: var(--white-background-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }

  @b bar-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    font-family: Nunito;
    align-items: center;
  }

  @b bar-legend {
    font-size: 12px;
    flex: 0 1 60%;
    margin-left: -20px;
    & span {
      border-left: 5px solid;
      padding-left: 8px;
      display: inline-block;
      margin: 5px 20px;
    }
  }

  @b filter {
    font-size: 10px;
  }
  @b filter-tag {
    padding: 2px 7px;
    margin-left: 8px;
    display: inline-block;
    color: var(--text-dark-color);
    background-color: rgba(1, 119, 157, 0.1);
  }

  @b date-picker {
    width: 200px;
    /* padding-bottom: 0.5rem; */
    border-bottom: 1px solid var(--accent-color);
    line-height: 1;

    & .ant-input {
      font-size: 1.1rem;
      color: var(--accent-color);
      padding: 0;
      line-height: inherit;
      height: auto;
      font-family: Dosis;

      & input {
        line-height: inherit;
        padding: 0;
        font-weight: 500;
      }
    }

    & .ant-calendar-picker-icon {
      display: none;
    }

    & .ant-calendar-picker-input {
      border: none;
      padding-bottom: 0.5rem;
      height: 25px;
      vertical-align: middle;
      line-height: 1;
    }
  }
  @media (--only-mobile) {
    @b content {
      padding: 2rem 1rem 0rem 1rem;
    }

    @b header-selector {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
       .graph-timezone, .graph-location {
        width: 100%;
        min-width: auto;
      }

      & > .group {
        margin-right: 0;
        width: 49%
      }
    }

    @b header {
      flex-wrap: wrap;
    }

    @b dropdown {
      @e label {
        font-size: 0.7rem;
      }
    }

    @b tips {
      display: none;
    }

    .ant-calendar-range {
      width: 100vw!important;
      padding: 1rem;
    }
  }
}

#chartjs-tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: var(--text-white-color);
  border-radius: 3px;
  transition: all 0.1s ease;
  pointer-events: none;
  z-index: 99;
  right: 10px;
  top: 0;
  transform: translate(0, -100%);
  position: absolute;
}

#chartjs-tooltip table {
  white-space: nowrap;
}

#chartjs-tooltip.is-fixed {
  top: 5%;
  position: fixed;
  margin-top: 50px;
  transform: translate(0, 0);
}

.inKiosk #chartjs-tooltip {
  left: auto;
  right: 0;
  margin-left: 0;
}

@media (max-width: 1200px) {
  #chartjs-tooltip {
    left: auto;
    margin-left: 0px;
    right: 20px;
  }
}

#chartjs-tooltip .chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.line-chart-wrapper {
  position: relative;
}
#chartjs-tooltip-line {
  position: absolute;
  height: 90%;
  top: 10%;
  width: 0;
  border-left: 1px dashed #999;
}
.inKiosk {
  background: transparent;
  width: 100%;
}
.inKiosk .graph-panel {
  box-shadow: none;
}
.inKiosk .ant-select-selection,
.inKiosk .ant-input {
  background: transparent;
}
.inKiosk .ant-select,
.inKiosk .ant-calendar-picker {
  vertical-align: middle;
}
.loading-container {
  text-align: center;
  width: 100%;
  display: block;
  padding: 30px 50px;
  margin: 20px 0;
}

@media (--only-mobile) {
  .graph-panel {
    overflow: hidden;
  }
  .line-chart-wrapper {
    height: 400px;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .line-chart-wrapper .chart-container {
    height: 360px;
    width: 2400px;
    display: inline-block;
  }
}
