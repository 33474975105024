

@component-namespace top-note-bar {
  @b wrap {
    position: relative;
    background-color: var(--text-dark-color);
    color: var(--primary-color);
    height: 3rem;
    line-height: 3rem;
    font-size: 14px;
  }
  @b container {
    width: 880px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
  }
  @b container a {
    color: var(--primary-color) !important;
    background: #2f94ba;
    padding: 0px 3px;
    border-radius: 2px;
  }
  @b close {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.feedback-btn {
  position: fixed;
  bottom: 30%;
  right: 18px;
  writing-mode: tb-rl;
  cursor: pointer;
  background: var(--accent-color);
  padding: 15px 10px;
  width: 36px;
  line-height: 18px;
}
.feedback-tips {
  margin-bottom: 15px;
}
.feedback-tips p {
  line-height: 1.5;
}
