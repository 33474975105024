@component-namespace workspaces {

}

.icon-desc {
  margin-left: 8px;
}

.ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab {
  padding-left: 0;
  padding-right: 0;
}

@component-namespace newWS-modal {
  @b footer {
    text-align: right;

    & .ant-btn + .ant-btn {
      margin-left: 15px;
    }
  }

  @b body {
    margin: 30px 0;
  }

  @b label {
    margin-bottom: 10px;
  }

  @b input-tip {
    margin-top: 5px;
    font-size: 12px;
  }
}

@media (--only-mobile) {
  .ant-table-body {
     table {
      width: unset;
    }
  }

}
