@component-namespace custom {
  @b table {
    & .ant-table {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }

    & .ant-table-bordered .ant-table-content {
      border-left: 1px solid #e8e8e8;
    }

    & table {
      padding: 0 !important;
    }

    & .ant-table-thead th {
      font-size: 12px;
      line-height: 1rem;
      font-family: Dosis;
      font-weight: bold;
      border-radius: 0 !important;
    }

    & .ant-table-small table td {
      font-size: 12px;
      line-height: 1rem;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      color: var(--text-dark-color);
    }

    & .ant-table-tbody > tr:hover > td {
      background: var(--secondary-background-light-mobile);
    }

    & .ant-table-body {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @b table {
    @m dark th {
      background: var(--layout-border-color) !important;
    }
    @m light th {
      background: var(--secondary-background-light) !important;
    }
  }
}
.centerAlign .ant-table-thead th,
.centerAlign .ant-table-tbody td {
  text-align: center;
}
