/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url('./dosis-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Dosis Regular'), local('Dosis-Regular'),
       url('./dosis-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dosis-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dosis-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./dosis-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dosis-v7-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
}
/* dosis-500 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  src: url('./dosis-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Dosis Medium'), local('Dosis-Medium'),
       url('./dosis-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dosis-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dosis-v7-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./dosis-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dosis-v7-latin-500.svg#Dosis') format('svg'); /* Legacy iOS */
}
/* dosis-600 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  src: url('./dosis-v7-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
       url('./dosis-v7-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dosis-v7-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dosis-v7-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./dosis-v7-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dosis-v7-latin-600.svg#Dosis') format('svg'); /* Legacy iOS */
}
/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: url('./dosis-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Dosis Bold'), local('Dosis-Bold'),
       url('./dosis-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dosis-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dosis-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./dosis-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dosis-v7-latin-700.svg#Dosis') format('svg'); /* Legacy iOS */
}
/* dosis-800 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 800;
  src: url('./dosis-v7-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Dosis ExtraBold'), local('Dosis-ExtraBold'),
       url('./dosis-v7-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dosis-v7-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dosis-v7-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./dosis-v7-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dosis-v7-latin-800.svg#Dosis') format('svg'); /* Legacy iOS */
}