

@component-namespace location-need {
  @b container {
    padding: 100px 0;
    width: 500px;
    margin: 0 auto;
  }

  @b selector {
    width: 100%;

    & .ant-select-selection__rendered {
      line-height: 40px;
      margin-left: 0px;
    }
  }

  @b tip {
    color: var(--text-dark-color);
    font-family: Nunito;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
