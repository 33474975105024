@component-namespace dou-chart {
  @b wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @b info {
    position: absolute;
    text-align: left;
    width: 160px;
    top: 0;
    left: 110px;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;
    flex-direction: column;
  }

  @b count {
    color: var(--text-dark-color);
    font-size: 12px;
    font-family: Dosis;
    & .num {
      font-size: 20px;
      font-weight: bold;
    }
  }

  @b status {
    color: var(--grey-border-color);
    font-size: 12px;
  }
}
