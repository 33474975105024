

.ant-select-sm {
   & .ant-select-selection--single {
    height: 26px;
  }

   & .ant-select-selection__placeholder {
    font-size: 15px;
  }

   & .ant-select-selection__rendered {
    line-height: 24px;
  }

   & .ant-select-selection-selected-value {
    font-size: 15px;
  }
}

/* .ant-select-selection__placeholder {
  font-size: 12px;
}

.ant-input::placeholder {
  font-size: 12px;
} */

.ant-select-dropdown-menu-item-active {
  background: var(--secondary-background-light);
}

.ant-dropdown-menu-item-selected {
  background: var(--secondary-background-light);
}

.ant-select-dropdown-menu-item:hover {
  background: var(--secondary-background-light);
}

.ant-input-sm {
  height: 26px;
  font-size: 12px;

   &::-webkit-input-placeholder {
    font-size: 12px;
  }
}

.ant-btn-sm {
   &.ant-btn {
    font-size: 12px;
  }
}

.ant-btn-group > .ant-btn-sm {
  line-height: 24px;
}

.ant-dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.ant-dropdown-menu-item {
  /* line-height: 16px; */
  /* font-size: 12px; */

  & > a {
    color: var(--text-grey-color) !important;
  }

   &:hover {
    background-color: var(--secondary-background-light) !important;
    & > a {
      color: var(--accent-color) !important;
    }
  }
}

.ant-select-dropdown-menu-item {
  color: var(--text-grey-color) !important;
   &:hover {
    background-color: var(--secondary-background-light) !important;
    & > a {
      color: var(--accent-color) !important;
    }
  }
}

.ant-back-top-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC)
    100%/100% no-repeat !important;
}

.ant-table-tbody > tr:hover > td, .ant-table-tbody .ant-table-row-hover > td {
  background: #F4F9FE !important;
}