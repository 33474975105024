@component-namespace sibeibackbutton {
  .floating-button {
    position: fixed;
    opacity: 0.8;
    z-index: 999999;
    width: 45px;
    height: 45px;
    bottom: 100px;
    right: 17px;
    cursor: pointer;
    box-shadow: #bbb 1px 1px 10px;
    border-radius: 30px;
    padding-top: 6px;
    padding-left: 6px;
    background-color: #fff;
  }

  .sibei-back-button {
    width: 32px !important;
    height: 32px !important;
  }
}
