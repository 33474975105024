
@import "./common.css";

@component-namespace ds {
  @b link {
    color: var(--accent-color);
    font-weight: bold;
  }
  @b tooltip {
    font-style: italic;
  }
  @b detail {
    display: flex;
  }

  @b detail-header {
    padding: 6px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    & .ant-breadcrumb span {
      display: inline-block;
    }

    & svg {
      vertical-align: middle;
      height: 14px;
    }

    & .ant-breadcrumb {
      font-size: 12px;
    }

    & .ant-breadcrumb {
      & .label {
        margin-left: 5px;
      }

      & span {
        vertical-align: middle;
      }

      & small {
        margin-left: 5px;
        color: var(--grey-border-color);
      }
    }
  }

  @b date-time {
    color: var(--grey-border-color);
  }

  @b limit-selector {
    width: 150px;
  }

  @b channel-unit-selector {
    width: 90px;
  }

  @b channel-unit-selector .ant-select-selection-selected-value {
    line-height: 1.5;
  }

  @b info-panel {
    flex: 0 0 38%;
    background-color: #f9fdff;
    padding-bottom: 20px;
    border: 1px solid var(--layout-border-color);
  }

  @b channel-list {
    flex: 0 0 62%;
  }

  @b channel-list .empty-title {
    color: var(--text-dark-color);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 15px 0;
  }

  @b channel-list .empty-tip {
    text-align: center;
    font-size: 12px;
    color: var(--grey-border-color);
  }

  @b raw-data-panel {
    margin-top: 30px;
    margin-bottom: 50px;

    & .body {
      color: var(--grey-border-color);

      & td.rtd-1 {
        color: var(--grey-border-color);
      }

      & td.rtd-2 {
        font-weight: bold;
      }

      & td.rtd-3 {
        color: var(--grey-border-color);
      }
    }
  }

  @b raw-data-panel-header {
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;

    & .label {
      font-weight: bold;
    }

    & .read-at span {
      color: var(--grey-border-color);
      font-size: 12px;
    }
  }

  @b list-header {
    display: flex;
    background-color: var(--layout-border-color);
    font-size: 12px;
    font-weight: bold;
  }

  @b list-td {
    line-height: 30px;
    text-align: center;
  }

  @b channel-row {
    & td.td-1 {
      border-left: 3px solid;

      & .second {
        color: var(--text-dark-color);
      }

      & .item-origin-name {
        margin-left: 18px;
        margin-top: 5px;
        color: var(--grey-border-color);
      }
    }

    & .unassign {
      color: var(--grey-border-color);
      position: relative;

      & .hover {
        display: none;
      }
    }
  }

  @b channel-row.st-offline .td-1 {
    border-left-color: #a6a6a6;
  }
  @b channel-row.st-flatline .td-1 {
    border-left-color: #faad5b;
  }
  @b channel-row.st-online .td-1 {
    border-left-color: var(--status-online-color);
  }

  @b img {
    width: 192px;
    height: 192px;
    background: #8497a3;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    overflow: hidden;

    & img {
      width: 100%;
      /* height: 100%; */
    }

    &:hover > .ds-img-tip {
      display: table;
    }
  }

  @b img-tip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--text-white-color);
    cursor: pointer;
    text-align: center;

    & .ant-upload {
      color: var(--text-white-color);

      &.ant-upload-drag {
        background: transparent;
        color: var(--text-white-color);
        border: none;
      }
    }

    & > span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  @b status {
    text-align: center;
  }

  @b action {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  @b calibrate-btn {
    cursor: pointer;
    &:hover svg g {
      fill: var(--accent-color);
    }
  }
  @media (--only-mobile) {
    @b channel-list {
      display: none;
    }
    @b info-panel {
      width: 100%;
      flex: auto;
      padding: 0 1rem 2rem 1rem;
    }
    @b detail-header {
    padding: 0.375rem 1rem;
    }
  }
}

.untitled,
.unassigned {
  color: #999;
  font-weight: normal;
  margin-right: 10px;
  font-style: italic;
  position: relative;
}
.unassigned {
  cursor: pointer;
}
.unassigned .assign-btn {
  display: none;
}
.unassigned:hover .text {
  display: none;
}
.unassigned:hover .assign-btn {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--status-online-color);
}
.spec-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 20px;
}
.spec-list dt {
  flex: 0 0 40%;
  font-size: 12px;
  text-align: right;
  padding: 0 5px;
  color: #999;
  line-height: 1.8rem;
}
.spec-list dd {
  flex: 0 0 60%;
  padding: 0 5px;
  font-weight: bold;
  line-height: 1.8rem;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.spec-list .ant-select {
  font-weight: normal;
}

.row-1-edit {
  margin-right: -20px;
}

.datasource-name-input {
  width: 120px;
  font-size: 12px;
}
.datachannel-name-input {
  width: 80px;
  font-size: 12px;
}
