

@component-namespace noti-setting {
  @b panel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  @b panel.block {
    display: block;
    margin-bottom: 40px;
  }

  @b line {
    width: 200px;
    margin-top: 40px;
    border-top: 1px solid var(--accent-color);
  }

  @b wrap {
    margin-top: 40px;
  }

  @b clickable {
    cursor: pointer;
    /* background-color: #f5f5f5; */
  }

  @b left {
    font-size: 12px;
    flex: 0 1 700px;
  }

  @b top {
    font-size: 12px;
    margin-bottom: 20px;
  }

  @b table {
    margin-bottom: 40px;
  }

  @b bold {
    font-size: 14px;
    font-weight: bold;
  }
}
