@component-namespace noti {
  @b content {
    margin: 0 auto;
    padding: 20px 0;
  }
  @b header-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @b options {
    @e filter_item {
      line-height: 28px;
      vertical-align: middle;
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
      position: relative;

      & .ant-calendar-picker {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }

      & svg {
        margin-right: 5px;
        height: 14px;
        fill: var(--grey-border-color);
        vertical-align: middle;
      }

      & span {
        font-weight: bold;
        vertical-align: middle;
        display: inline-block;
      }

      & .ant-badge-count {
        background: var(--accent-color);
      }

      & .current {
        color: var(--text-white-color);
      }
      @m filter {
        position: relative;
        padding-right: 25px;
        box-sizing: content-box;
        margin-right: 0;
      }
    }

    @e badge {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--accent-color);
      text-align: center;
      color: var(--text-white-color);
      line-height: 16px;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  @b row-indicator-item {
    text-align: left;
    padding-left: 10%;
    margin: 10px 0;
    display: flex;

    & span {
      margin-left: 5px;
    }
  }

  @b row-icon {
    flex: 0 1 10%;
    text-align: center;
  }

  @b row-info {
    flex: 1 1 45%;
    line-height: 30px;
    padding-left: 25px;
  }

  @b header-date {
    font-size: 18px;
    color: var(--text-dark-color);
    font-family: Dosis;
    font-weight: 500;
    margin-bottom: 15px;
  }

  @b header-left {
    color: var(--text-dark-color);
    font-size: 24px;
    font-family: Dosis;
    line-height: 32px;
  }

  @b header-right {
    font-family: Nunito;
  }

  @b station {
    text-align: left;
    padding: 16px 8px;
  }

  @b title {
    font-family: Nunito;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    line-height: 1.5;
  }
  @b rep {
    color: var(--text-grey-color);
    font-size: 12px;
    margin-left: 5px;
  }

  @b date {
    color: var(--text-grey-color);
    font-size: 12px;
  }

  @b status {
    font-family: Nunito;
    color: var(--text-white-color);
    font-weight: bold;
    font-size: 12px;
    padding: 1px 3px;
    border-radius: 1px;
    margin-left: 5px;
    line-height: 16px;
    white-space: nowrap;

    &.offline {
      background-color: var(--danger-color);
    }

    &.flatline {
      background-color: #ff9c00;
    }
  }
  @b status-text {
    background-color: transparent !important;
    &.offline {
      color: var(--danger-color);
    }

    &.flatline {
      color: #ff9c00;
    }
  }

  @b datetime-selected {
    font-size: 12px;
    padding: 2px 5px;
    line-height: 1.4;
    margin-left: 5px;
    display: inline-block;
    border-radius: 10px;
    background-color: #efefef;

    & i {
      vertical-align: middle;
    }

    & span {
      display: inline-block;
      border-left: 1px solid #ddd;
      padding-left: 5px;
      margin-left: 5px;
    }
  }

  @b tableIcon {
    width: 24px;
    height: 24px;
    margin-right: 18px;
    margin-left: 26px;
    margin-top: 5px;
  }

  @b resolve {
    text-align: left;
  }

  @b resolved {
    color: #2a7598;
    font-size: 12px;

    & i {
      color: #3388a9;
      font-size: 14px;
      margin-right: 3px;
      vertical-align: middle;
    }
  }

  @b list-wrap {
    margin-top: 20px;
  }
  @b list-no_expand {
    & .ant-table-row-expand-icon {
      display: none;
    }

    & + .ant-table-expanded-row {
      display: none;
    }
  }

  @b col-date {
    color: var(--grey-border-color);
    font-family: Dosis;
    text-align: left;

    & .date-0 {
      color: #333;
      font-weight: bold;
    }
  }

  @media (--only-mobile) {
    @b content {
      padding: 1rem;
    }
    .ant-table-row-expand-icon {
      /* display: none; */
    }
    .ant-table-row-expand-icon-cell, .ant-table-expand-icon-th {
      width: 0px!important;
      display: flex;
      min-width: 0px;
      height: 0px;
      margin: 0px;
      padding: 0px 0px!important;
    }
    .notification-type-col {
      width: min-content;
      padding: 0px 0px!important;
      max-width: 160px;
    }
    .notification-type-icon {
      width: min-content;
      padding: 0px 0px !important;
    }
    .noti-station-box {
      display: flex;
      flex-wrap: wrap;
    }
    .noti-tableIcon {
      margin-left: 0px;
      margin-right: 0px;
    }
    .noti-row-indicator-item {
      padding-left: 0px;
    }
    .notification-description-col {
      width: 100px;
    }

    .ant-table-expanded-row td:first-child {
     width: 0px;
     margin: 0px;
     padding: 0px!important;
    }
  }
}
