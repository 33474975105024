.scroll-affix {
  position: absolute;
  top: 0;
  right: -250px;
}

.scroll-affix-title {
  width: 250px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  font-family: Dosis;
  font-weight: bold;
  color: rgba(74, 74, 74, 1);
  line-height: 26px;
}

.scroll-affix-list {
  margin: 16px 0;
  padding-left: 0;
  font-size: 12px;
  list-style: none;
  border-left: 1px solid var(--layout-border-color);
}

.scroll-affix-list a {
  display: block;
  width: 110px;
  margin-left: -1px;
  padding-left: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: hidden;
  color: #314659;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 2px solid transparent;
  transition: all 0.3s ease;

  &.current {
    color: var(--accent-color);
    border-color: var(--accent-color);
    font-weight: bold;
  }
}
