

@component-namespace datasource_modal {
  @b calibration {
    padding: 10px 0;

    @e row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @s text {
        margin-top: -8px;
        margin-bottom: 0;
      }
    }

    @e label {
      width: 60px;
      margin-right: 30px;
      font-size: 14px;
      text-align: right;
      color: var(--text-dark-color);
    }

    @e content {
      width: 360px;
      vertical-align: middle;
      margin-bottom: 5px;
    }

    @e select {
      width: 100%;
    }

    @e factor {
      width: 156px;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;

      & .collection-add_text {
        margin-left: -8px;
        font-weight: normal;
      }

      @s notification {
        width: 113px;

        & .ant-btn {
          height: 26px;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 26px;
        }
      }
    }

    @e value {
      width: 76px;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;

      @s notification {
        width: 70px;
      }
    }

    @e delete {
      width: 20px;
      font-size: 20px;
      position: relative;
      bottom: -4px;
    }

    @e button_group {
      text-align: center;

      & button {
        width: 136px !important;
        border-radius: 2px !important;
      }
    }
  }

  @b container {
    padding: 0.5rem 1rem;
  }

  @b title {
    font-size: 24px;
  }

  @b body {
    margin-top: 1rem;
    font-size: 12px;

    & p {
      font-size: 12px;
      margin-bottom: 15px;
    }
  }

  @b button_group {
    margin-top: 1rem;

    & button {
      height: 24px;
      font-size: 12px !important;
    }

    & button + button {
      margin-left: 15px;
    }
  }
}
