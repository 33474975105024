/* design size 414 */
/* base font size 16 */
/* 
  @media only screen and (max-width: 800px), only screen and (max-device-width:800px) {
  html,body {
      font-size: 16px;
  }
} */
  /* @media only screen and (max-width: 720px), only screen and (max-device-width:720px) {
  html,body {
      font-size:27.82608695652174px;
  }
}
  @media only screen and (max-width: 640px), only screen and (max-device-width:640px) {
  html,body {
      font-size:24.734299516908212px;
  }
}
  @media only screen and (max-width: 600px), only screen and (max-device-width:600px) {
  html,body {
      font-size:23.18840579710145px;
  }
}
  @media only screen and (max-width: 540px), only screen and (max-device-width:540px) {
  html,body {
      font-size:20.869565217391305px;
  }
}
  @media only screen and (max-width: 480px), only screen and (max-device-width:480px) {
  html,body {
      font-size:18.55072463768116px;
  }
} */
html,body {
  font-size: 16px;
}
  @media only screen and (max-width: 414px), only screen and (max-device-width:414px) {
  html,body {
      font-size:16px;
  }
}
  @media only screen and (max-width: 400px), only screen and (max-device-width:400px) {
  html,body {
      font-size:15.458937198067632px!important;
  }
}
  @media only screen and (max-width: 375px), only screen and (max-device-width:375px) {
  html,body {
      font-size:14.492753623188406px!important;
  }
}
  @media only screen and (max-width: 360px), only screen and (max-device-width:360px) {
  html,body {
      font-size:13.91304347826087px!important;
  }
}
  @media only screen and (max-width: 320px), only screen and (max-device-width:320px) {
  html,body {
      font-size:12.367149758454106px!important;
  }
}