html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0 !important;
}

h1,
h2 {
  color: var(--text-grey-color);
  font-family: "Dosis";
  line-height: var(--base-line-height);
  margin: 0;
}

h1.title {
  font-size: 72px;
  line-height: 91px;
}

h2.title {
  font-size: 2.25rem;
}

h3.subtitle {
  color: var(--text-grey-color);
  font-family: "Nunito";
  line-height: 1.6875rem;
  font-weight: 400;
  font-size: 1.25rem;
}

h4.subtitle {
  color: var(--text-grey-color);
  font-family: "Nunito";
  line-height: 1.6875rem;
  font-weight: 400;
  font-size: 1rem;
}

p {
  color: var(--text-dark-color);
  font-family: "Nunito";
  line-height: 1.25rem;
  font-size: 1rem;
  white-space: normal;
}

.small_text {
  font-size: 12px;
  line-height: 1rem;
}

.grey_text {
  color: var(--grey-border-color);
}

.accent_text {
  color: var(--accent-color);
}

.dark_text {
  color: var(--text-dark-color);
}

.line_icon {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

body .icon_disabled {
  color: var(--grey-border-color) !important;
  & .icon_disabled {
    color: var(--grey-border-color);
  }

  & path[stroke="#006B94"],
  & circle[stroke="#006B94"],
  & rect[stroke="#006B94"] {
    stroke: var(--grey-border-color) !important;
  }

  & path[fill="#006B94"],
  & circle[fill="#006B94"],
  & rect[fill="#006B94"] {
    fill: var(--grey-border-color) !important;
  }

  & path {
    fill: var(--grey-border-color) !important;
  }

  & #Rectangle {
    stroke: var(--grey-border-color) !important;
  }

  & .st5 {
    stroke: var(--grey-border-color) !important;
  }
}

.menu_text {
  width: 100%;
  text-align: center;
  display: block;
  padding: 8px 16px;
  transition: var(--base-transition);
  &:hover {
    font-weight: bold;
    color: var(--accent-color);
  }
}

.ant-dropdown-menu-item-selected .menu_text {
  font-weight: bold;
  color: var(--accent-color);
}

.danger_text {
  color: var(--danger-color);
}

.f10_text {
  transform: scale(0.75);
  font-size: 12px;
  display: inline-block;
}

.cricle_checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

h1,
h2,
h3,
h4 {
  &.alt {
    color: var(--text-white-color);
  }
}

body {
  color: var(--text-grey-color);
  min-height: 100%;
  font-family: "Nunito";
  line-height: var(--base-line-height);
  font-weight: normal;
  background: var(--text-white-color);
  &.bold {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.highlight {
  color: var(--accent-color);
  font-family: "Dosis";
  font-size: 7.5rem;
  line-height: 9.4375rem;
  font-weight: 400;
}

.button {
  font-size: 0.9375rem;
  font-family: "Nunito";
  font-weight: 700;
  &.dark {
    color: var(--text-white-color);
    background: var(--accent-color);
  }
  &.light {
    background: var(--text-white-color);
    color: var(--accent-color);
  }
}

a {
  color: var(--text-grey-color);
  &:hover,
  &:active,
  &:focus {
    color: var(--accent-color);
    text-decoration: none;
  }
}

a.accent {
  color: var(--accent-color);
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: var(--base-transition);
  &.is-visiable {
    opacity: 1;
    overflow: hidden;
    background: var(--layer-background-color);
    z-index: var(--layer-index);
  }
}

@media (--only-not-desktop) {
  body.is-locked {
    overflow: hidden;
  }

  h1.title {
    font-size: 3.375rem;
  }

  .layer {
    display: none;
  }
}

@media (--only-mobile) {
  h1.title {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }

  h2.title {
    font-size: 1.625rem;
    line-height: 2.0625rem;
  }

  h3.subtitle {
    line-height: 1.6875rem;
    font-size: 1rem;
  }
}

.headroom {
  z-index: var(--slide-menu-index) !important;
}
.headroom--pinned {
  border-bottom: 1px solid #ebebeb;
}

.active-badge .ant-badge-status-processing {
  background-color: #39ad53;
}

.active-badge .ant-badge-status-processing::after {
  border-color: #39ad53;
}

/* .fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
} */

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.appear-enter {
  opacity: 0.01;
  transform: scale(0.9);
}

.appear-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-out;
}

.appear-exit {
  opacity: 1;
  transform: scale(1);
}

.appear-exit-active {
  opacity: 0.01;
  transform: scale(0.9);
  transition: all 300ms ease-out;
}
