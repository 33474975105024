@component-namespace histories {
  @b page {
    & .history-item {
      line-height: 36px;
      display: flex;
      justify-content: space-between;
    }
    & .timestamp {
      color: var(--grey-border-color);
      font-size: 12px;
    }
    & .link {
      margin: 0 5px;
      font-weight: bold;
    }
  }
}
