

@component-namespace custom {
  @b input {
    font-size: 12px !important;
    padding: 5px 10px !important;
    line-height: 1rem !important;
    width: 140px !important;
    height: auto !important;
    background: none !important;
    border-radius: 3px;
    margin-right: -6px !important;

    @m normal.ant-input-disabled {
      color: var(--grey-border-color) !important;
      border-color: var(--grey-border-color) !important;
    }

    @m success.ant-input-disabled {
      color: var(--status-good-color) !important;
      border-color: var(--status-good-color) !important;
    }

    @m error.ant-input-disabled {
      color: #d93127 !important;
      border-color: #d93127 !important;
    }

    @m none.ant-input-disabled {
      color: var(--text-dark-color) !important;
      border: none !important;
      font-weight: bold;
      background: none !important;
      /* padding: 5px 0 !important; */
    }
  }

  @b input.ant-input-disabled {
    width: 120px !important;
  }

  @b input_icon {
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
    vertical-align: bottom;
    left: -2px;
    bottom: -5px;
    position: relative;
    z-index: 5;
    transform: scale(0.8);
    color: var(--text-white-color);

    @m success {
      background: var(--status-good-color);
    }

    @m error {
      background: #d93127;
      font-weight: bold;
      bottom: -5px;
      transform: scale(0.8);
      border-radius: 5px;
    }

    @m normal {
      font-weight: bold !important;
      background: var(--grey-border-color);
    }
  }
}
