@component-namespace header {
  @b outter-wrap {
    position: relative;
  }
  @b placeholder {
    height: 3.3125rem;
    width: 100%;
  }
  @b placeholder.has-note-bar {
    height: 6.3125rem;
  }
  @b container {
    background: var(--accent-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: var(--fix-header-index);
    border-bottom: 1px solid var(--blue-border-color);
  }

  @b content {
    max-width: var(--desktop-min-width);
    width: 100%;
    margin: 0 auto;
    position: relative;

    @s kiosk {
      max-width: 100%;
      box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24),
        0 1.5px 6px rgba(0, 0, 0, 0.12);

      & .header-desktop {
        padding: 0 1rem;
      }

      & .logo-wrap {
        position: relative;
        display: flex;
        align-items: center;
      }

      & .nav-back {
        font-size: 1.5rem;
        color: var(--text-white-color);
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }

  @b desktop {
    display: none;
    align-items: center;
     height: 3.3125rem;
    justify-content: space-between;
    @s login {
      justify-content: flex-start;
    }
  }

  @b logo {
    height: 1.3125rem;
    /* margin-right: 1.875rem;
    margin-left: 1rem; */
  }

  @b center {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
  }

  @b location {
    height: 100%;
    display: flex;
    width: 12.5rem;
    line-height: 100%;
    align-items: center;
    padding: 0 1rem;
    color: var(--text-white-color);
    border-right: 1px solid var(--blue-border-color);
    border-left: 1px solid var(--blue-border-color);
    @e select {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @b location {
    & .ant-select-selection {
      background: none;
      border: none;
      color: var(--text-white-color);
    }

    & .ant-select-arrow {
      color: var(--text-white-color);
      right: 0;
    }

    & .ant-select-selection__rendered {
      margin: 0;
    }

    & > i {
      font-size: 1rem;
      color: var(--text-white-color);
      margin-right: 0.75rem;
      position: relative;
      bottom: 1px;
    }
  }

  @b search {
    margin-right: 1.5rem !important;
    width: 100%;

    @e text {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @e title {
      font-size: 14px;
      line-height: 17px;
      color: var(--text-dark-color);
      font-weight: bold;
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
    }

    @e more {
      font-size: 0.75rem;
      color: var(--accent-color);
    }

    @e dropdown {
      & .ant-select-dropdown-menu {
        padding: 0.5rem 0;
        max-height: 26.56rem;
      }

      & .ant-select-dropdown-menu-item-group-title {
        padding: 0 1.25rem;
      }

      & .ant-select-dropdown-menu-item-active {
        color: var(--accent-color);
        font-weight: bold;
      }

      & .ant-select-dropdown-menu-item {
        color: var(--grey-border-color);
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  @b search {
    & .ant-select-selection {
      background: none;
    }

    & .ant-select-selection__placeholder {
      margin-left: 3.13rem !important;
    }

    & .ant-input {
      background: none;
      color: var(--text-white-color);
      margin-left: 1.25rem;
      border: none;
      outline: none;

      &:focus {
        border-bottom: 1px solid #16a8e0;
      }
    }

    & .ant-select-selection__clear {
      background: none;
      color: var(--text-white-color);
    }

    & .ant-input-suffix {
      display: none;
    }
  }

  @b user {
    width: 10.5rem;
    cursor: pointer;
    color: var(--text-white-color);
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;

    & > a {
      color: var(--text-white-color);
      line-height: 0;
      &:hover {
        color: var(--text-white-color);
      }
      &:focus {
        color: var(--text-white-color);
      }
    }

    & > span {
      min-width: 1.88rem;
      min-height: 1.31rem;
      display: inline-block;
      position: relative;
      bottom: -1px;
    }
  }

  @b notification {
    @e overlay {
      & .ant-popover-content {
        width: 18.75rem;
      }

      & .ant-popover-arrow {
        display: none;
      }

      & .ant-popover-inner {
        background-color: var(--white-background-color);
      }

      & .ant-popover-arrow {
        background-color: var(--white-background-color);
      }

      & .ant-popover-inner-content {
        max-height: 18.75rem;
        overflow: auto;
        color: #333;
        font-family: "Nunito";
        font-style: normal;
        padding: 0;
      }

      & .noti-item-wrap {
        padding: 0.63rem 1.25rem 0.63rem 3.75rem;
        position: relative;
      }

      & .noti-item-wrap + .noti-item-wrap {
        border-top: 1px solid #e5e5e5;
      }

      & .noti-empty-tip {
        padding: 1.56rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .noti-item-ws {
        color: var(--grey-border-color);
      }

      & .noti-item-icon {
        position: absolute;
        top: 0.94rem;
        left: 1.25rem;
      }

      & .noti-title {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
      }

      & .noti-tableIcon {
        margin: 0;
      }

      @s menu {
        & .ant-popover-content {
          width: 10.63rem;
        }

        & .ant-popover-inner-content {
          max-height: none;
        }
      }
    }
  }

  @b mobile {
    height: 3.3125rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: var(--text-white-color);
    padding: 0 1rem;

    @e login {
      color: var(--text-white-color);
    }
  }

  @b layer {
    display: none;
    position: absolute;
    user-select: none;
    right: 10px;
    z-index: var(--layer-index);
    margin-bottom: -100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16),
      0 3px 6px 2px rgba(0, 0, 0, 0.09);
    min-width: 9.5rem;
    border-radius: 3px;
    background: var(--white-background-color);
    @s visiable {
      display: block;
    }

    @e content {
      position: relative;
      width: 100%;
      padding: 1rem 1.25rem;
    }

    @e more {
      overflow: hidden;
      margin-top: 1.5rem;
      cursor: pointer;

      & > a {
        float: right;
      }
    }

    @e traingle {
      width: 0;
      height: 0;
      border: 0.9375rem solid transparent;
      border-bottom-color: var(--primary-color);
      position: absolute;
      top: -1.875rem;
      right: 1.875rem;
    }

    @m secend_list {
      border-top: 1px solid var(--input-border-color);
      border-bottom: 1px solid var(--input-border-color);
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @m expand_list {
      border-top: 1px solid var(--input-border-color);
      margin-top: 1rem;
      padding-top: 1rem;
      padding-left: 1rem;
    }

    @e list {
      & > li {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @e logout {
      width: 100%;
      margin-top: 1.25rem;
      font-weight: normal !important;
      border-radius: 5px !important;
      height: 1.5rem !important;
      font-size: 0.75rem !important;
    }

    @e close {
      display: none;
      text-align: center;
      margin-bottom: 3rem;

      & > span {
        display: inline-block;
        cursor: pointer;
        font-weight: bolder;
        padding: 1rem 1.25rem;
        color: var(--accent-color);
        border-top: 2px solid var(--accent-color);
      }
    }
  }
}

@media (--only-desktop) {
  .header-desktop {
    display: flex;
  }

  .header-mobile {
    display: none;
  }
}

@media (--only-not-desktop) {
  @component-namespace header {
    @b layer {
      right: 0;
      width: 100%;
      height: calc(100vh - 3.3125rem);
      overflow-x: hidden;
      overflow-y: auto;

      @e content {
        padding: 2.5rem;
      }

      @e logout {
        margin-top: 1.25rem;
        font-weight: normal !important;
        border-radius: 5px !important;
        height: 2rem !important;
        font-size: 1rem !important;
      }

      @e close {
        display: block;
      }
    }
  }
}

.location-information {
  .ant-popover-content {
    width: fit-content;
  }
}

.qr-code-modal {
  position: fixed;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
  z-index: 1000;
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  align-content: center;
}
