

@component-namespace overview {
  @b tab_line {
    width: 100vw;
    margin-left: calc(calc(100vw - var(--desktop-min-width)) / -2);
    height: 1px;
    background: var(--secondary-background-dark);
    position: relative;
    top: 45px;
  }

  @b tab {
    & .ant-tabs-tab {
      font-family: Dosis;
      color: var(--grey-border-color);
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & .ant-tabs-ink-bar {
      height: 3px;
    }

    & .ant-tabs-bar {
      border: none;
      margin-bottom: 1px;
    }

    & .ant-tabs-nav-wrap {
      padding-right: 3rem;
      padding-left: 0;
    }

    & .ant-tabs-tab-next {
      right: 3.125rem;
    }

    & .ant-tabs-tab-active {
      color: var(--accent-color);
      font-weight: 500;
    }
  }

  @b icons {
    /* float: right; */
    position: absolute;
    z-index: 5;
    right: 0;
    display: flex;
    height: 44px;
    align-items: center;
    cursor: pointer;
    & .iconfont {
      font-size: 30px;
      display: inline-block;
      color: var(--accent-color);
    }

    /* & path {
      fill: var(--accent-color) !important;
    } */

    & #Rectangle {
      fill: white !important;
      stroke: var(--accent-color) !important;
    }

    & .st5 {
      stroke: var(--accent-color) !important;
    }

    /* & .icon_disabled .iconfont {
      color: var(--grey-border-color);
    }

    & .icon_disabled path {
      fill: var(--grey-border-color) !important;
    }

    & .icon_disabled #Rectangle {
      fill: white !important;
      stroke: var(--grey-border-color) !important;
    }

    & .icon_disabled .st5 {
      stroke: var(--grey-border-color) !important;
    } */

    & img {
      margin-left: 1.5rem;
      width: 2.125rem;
      height: 2.125rem;

      &::after {
        content: "";
        display: inline-block;
        line-height: 100%;
        vertical-align: middle;
      }
    }
  }

  @media (--only-mobile) {
  @b tab {
      & .ant-tabs-nav-wrap {
        height: 0px;
      }
    }
  }
}

@media (--only-not-desktop) {
  @component-namespace overview {
    @b tab_line {
      width: 100%;
      margin-left: 0;
    }

    @b icons {
    }

    @b icons img {
      margin: 0 0.5rem;
    }
  }
}

.overview-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.overview-modal {
  text-align: center;
  white-space: nowrap;
}

.overview-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
