@component-namespace signinup {
  @b container {
    display: flex;
    width: 56.25rem;
    max-width: 100%;
    min-height: 40rem;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.23);
    margin: 0 auto;
    margin-bottom: 1.875rem;
  }
  @b error-msg {
    background: var(--white-background-color);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.23);
    border: 1px solid rgba(196, 196, 196, 1);
    padding: 0.625rem 3.125rem 0.625rem 1.25rem;
    margin-bottom: 1.25rem;
    color: #000000;
    position: relative;

    & i {
      color: var(--danger-color);
    }

    & .close {
      color: var(--text-dark-color);
      position: absolute;
      top: 0.75rem;
      cursor: pointer;
      right: 0.625rem;
    }

    & .text {
      margin-left: 0.625rem;
    }
  }

  @b wrap {
    margin-bottom: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 3.125rem;
  }

  @b logo {
    text-align: center;
    height: 2rem;

    @e container {
      text-align: center;
      margin-bottom: 2.5rem;
    }
  }

  @b form {
    & .ant-form-item {
      margin-bottom: 0.625rem;
    }

    & .login-form-forgot {
      float: right;
    }
  }

  @b left {
    flex: 1;
    padding: 5rem 5.125rem;

    & h3 {
      font-size: 1.25rem;
    }

    & a {
      color: var(--accent-color);
    }
  }

  @b right {
    flex: 0 1 27.0625rem;
    background: url(../../images/loginbg.jpg) no-repeat;
    background-size: cover;

    & h1 {
      font-size: 2.5rem;
    }

    & h4 {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  @b tip {
    margin: 1.25rem 0;
    font-size: 1rem;
    color: var(--text-dark-color);
    line-height: 1.375rem;
  }
  @b resetpwd-tip {
    margin: 5px 0 1.25rem 0;
    font-size: 0.875rem;
  }

  @b ext {
    font-size: 0.875rem;
  }
  @b icon {
    text-align: center;
    color: var(--accent-color);
    font-size: 3.125rem;
    margin-bottom: 1.875rem;
  }

  @b new-workspace-container {
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.23);
    margin: 0 auto;
    width: 37.5rem;
    padding: 1.875rem;
  }

  @media (--only-mobile) {
    @b container {
      width: 100%;
      flex-direction: column-reverse;
      margin-bottom: 0px;
    }

    @b wrap {
      margin-bottom: 0px;
    }

    @b right {
      display: none;
    }
    @b left {
      padding: 5rem 2rem;
    }
    .signiup-wrap-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
