@component-namespace video {
  @b container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
}
