@component-namespace navbar {
  @b wrapper {
    background: var(--accent-color);
    width: 100%;
    /* padding-top: 3.3125rem; */

    &.light {
      background: var(--text-white-color);
      box-shadow: 0px 2px 4px 0px rgba(10, 10, 10, 0.05);
    }
  }

  @b header {
    display: flex;
    justify-content: space-between;
    font-family: Nunito;
    color: var(--text-white-color);

    & a {
      color: var(--text-white-color);
    }
    & a:hover {
      color: var(--text-white-color);
    }

    & .ov-right {
      display: flex;
      align-items: center;

      & .ant-btn {
        margin-left: 1.25rem;
        padding: 0 0.5rem;
        height: 1.625rem;

        & .icon {
          fill: var(--text-white-color);
          width: 1.5rem;
          height: 1.5rem;
        }

        &:hover .icon {
          fill: var(--text-white-color);
        }
      }
    }

    & .ov-summary-item {
      margin-right: 1.875rem;
      text-align: center;
      font-size: 0.75rem;
      color: var(--text-white-color);
      line-height: 1.4;
      white-space: nowrap;
      cursor: pointer;

      & span {
        font-size: 1.5rem;
        color: var(--text-white-color);
        font-weight: 500;
        font-family: Dosis;
      }
    }

    & .ov-left {
      margin-right: 1.875rem;
    }
  }

  @b container {
    max-width: var(--desktop-min-width);
    width: 100%;
    position: relative;
    /* display: flex; */
    /* justify-content: space-between; */
    margin: 0 auto;
    overflow: hidden;
    padding: 0.7rem 1.4rem 0.7rem 4rem;

    &.block {
      padding: 0;
    }

    & .back-home {
      position: absolute;
      top: 50%;
      margin-top: -11px;
      left: 0;
      color: white;
    }

    & .back-home .link {
      cursor: pointer;
      margin-right: 0.625rem;
      color: white;
    }
  }

  @b traingle {
    border: 5px solid transparent;
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom-color: var(--primary-color);
    position: absolute;
    bottom: 0;
  }

  @b icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  @b item {
    float: left;
    cursor: pointer;
    width: 5.375rem;
    margin: 0 1rem;
    padding: 0.6rem 0 0.4rem 0;
    text-align: center;
    font-size: 0;
    line-height: 0;

    & path {
      fill: var(--grey-border-color);
    }

    & > p {
      color: var(--grey-border-color);
      font-size: 0.85rem;
      margin-top: 5px;
      margin-bottom: 0;
    }
    @s selected {
      border-bottom: 2px solid var(--accent-color);

      & > p {
        color: var(--accent-color);
        font-weight: 700;
      }

      & path {
        fill: var(--accent-color);
      }
    }

    @s disabled {
      & > p {
        opacity: 0.3;
      }

      & svg {
        opacity: 0.3;
      }
    }
  }
}

@media (--only-not-desktop) {
  @component-namespace navbar {
    @b wrapper {
      /* padding-top: 3.3125rem; */
    }

    @b container {
      display: flex;
      justify-content: space-between;
    }

    @b item {
      width: auto;
      height: auto;
      @s selected {
        background: none;
      }
    }

    @b item > p {
      display: none;
    }
  }


@media (--only-mobile) {
  .hidden-mobile, .ov-right {
    display: none!important
  }
  @component-namespace navbar {

    @b wrapper {
      padding: 0 1rem;
    }
    @b icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

}
