@component-namespace org {
  @b user-manage {
    & td .username {
      font-size: 14px;
      color: #1e1e1e;
    }

    & td .per-title {
      font-size: 14px;
      color: var(--text-dark-color);
      font-weight: bold;
    }

    & td .per-desc {
      font-size: 12px;
      color: var(--grey-border-color);
    }

    & td .email {
      color: var(--text-grey-color);
    }

    & td .user-settings {
      color: var(--accent-color);
      cursor: pointer;
    }
    & .action-new {
      text-align: right;
      color: var(--accent-color);
      font-weight: bold;
      margin-bottom: 15px;
      cursor: pointer;
    }
    & .action-new i {
      margin-right: 6px;
    }
  }
}

.org-add-user-modal .people-invite-access .ant-radio-group label {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.org-add-user-modal
  .people-invite-access
  .ant-radio-group
  label
  .ant-radio:after {
  display: none;
}
.org-add-user-modal .people-invite-access .ant-radio-group .per-title {
  font-size: 14px;
  font-weight: bold;
}
.org-add-user-modal .people-invite-access .ant-radio-group .per-desc {
  font-size: 12px;
  color: var(--grey-border-color);
}
.org-add-user-modal .footer {
  margin-top: 30px;
}
