

@component-namespace city {
  @b title {
    color: var(--accent-color);
    font-weight: bold;
    font-family: Dosis;
    font-size: 20px;
    line-height: 26px;
    padding: 16px 0;
    text-transform: capitalize;
    border-bottom: 1px solid var(--accent-color);
  }

  @b container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 0;
  }

  @b card {
    width: 280px;
    height: 100px;
    margin: 8px 0;
    position: relative;
    background-size: cover;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
    @s empty {
      box-shadow: none;
    }
  }

  @b layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);

    & h4 {
      color: var(--text-white-color);
      font-size: 18px;
      text-indent: 12px;
      line-height: 124px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
