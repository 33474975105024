@component-namespace custom {
  @b filter {
    & .ant-menu-sub.ant-menu-inline {
      max-height: 200px;
      overflow: auto;
    }

    & .ant-menu-submenu-title {
      padding: 0 16px !important;
      line-height: 32px !important;
      height: 32px !important;
      margin: 0;
      font-weight: bold;
      color: var(--text-dark-color);
      font-size: 0.75rem;
      line-height: 1rem;

      &[aria-expanded="true"] {
        background: var(--secondary-background-light);
      }
    }

    & .ant-menu-item {
      padding: 0 !important;
      margin: 0 !important;
      height: 32px !important;
      line-height: 32px !important;

      &:hover {
        background: var(--secondary-background-light);
      }
    }

    & .ant-menu-item-selected {
      background-color: var(--white-background-color) !important;

      &:after {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        cursor: default;
        font-size: 1rem; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        cursor: pointer;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: "liga";
        border: none;
        content: "check_circle";
        right: 16px;
        top: 8px;
        transition: none;
      }
    }

    & .menu_text {
      text-align: left;
    }

    & .ant-select-selection__rendered {
      margin: 0;
      padding: 0 8px;
      font-size: 12px;
      border-bottom: 1px solid var(--grey-border-color);
    }

    & .ant-select-selection__placeholder {
      padding-left: 8px;
    }
  }
}
