

@component-namespace exception {
  @b container {
    height: 80%;
    min-height: 500px;
    margin: 10% 0;
  }

  @b title {
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 24px;
  }

  @b message {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @b button {
  }
}

@media (--only-pad) {
  @component-namespace exception {
    @b col {
      text-align: center;
    }

    @b logo {
      height: 40vh;
    }
  }
}
