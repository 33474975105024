@component-namespace invoice {
  @b container {
    margin: 50px auto;
    position: relative;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.19);
    padding: 24px 40px;
    page-break-after: always;
    page-break-inside: avoid;
    width: 200mm;
    background-color: var(--white-background-color);
    height: 287mm;
    min-height: 28.7cm;

    & .report-pr-panel-footer {
      font-size: 12px;
    }

    & .strong {
      font-weight: bold;
    }

    & .custom-table--light th {
      background: transparent !important;
      color: var(--accent-color);
    }

    & .ant-table-thead > tr > th,
    & .ant-table-tbody > tr > td {
      padding-left: 0;
      padding-right: 0;
    }
    & .ant-table-thead > tr > th:last-child,
    & .ant-table-tbody > tr > td:last-child {
      text-align: right;
    }

    & .text-primary {
      color: var(--accent-color);
      font-weight: bold;
    }

    & .invoice-row td {
      font-size: 14px;
      color: var(--text-dark-color);
      font-weight: bold;
    }

    & .date {
      font-size: 12px;
      font-weight: normal;
    }
  }

  @b page-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #979797;
  }

  @b page-print-logo {
    align-items: center;
    display: flex;

    & > img {
      height: 20px;
    }
  }

  @b page-print-top {
    font-size: 12px;
    text-align: right;
    color: var(--text-dark-color);
  }

  @b page-print-top2 {
    font-size: 12px;
    color: var(--text-dark-color);
  }

  @b page-print-top2 .strong {
    padding-left: 10px;
  }

  @b page-title {
    color: var(--text-dark-color);
    font-size: 24px;
    text-align: center;
    margin: 20px 0;
  }

  @b page-summary-footer {
    text-align: right;
    font-size: 12px;
    float: right;
    margin-top: 16px;

    & .text-danger {
      color: var(--danger-color);
    }

    & td {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & .total2 {
      font-weight: bold;
      font-size: 14px;
      & .strong {
        font-size: 20px;
        color: var(--accent-color);
      }
    }

    & .total {
      background: var(--accent-color);
      color: white;

      & .width200 {
        width: 140px;
      }

      & .strong {
        font-size: 16px;
        padding-right: 5px;
      }
    }
  }
  @b page-summary-footer .strong {
    font-size: 14px;
    padding-left: 20px;
  }
}

@media print {
  .header-outter-wrap {
    display: none;
  }
  @component-namespace billing {
    @b container {
      padding: 0px;
      & .header-nav {
        display: none;
      }
    }
  }
  @component-namespace invoice {
    @b container {
      margin: 0;
      box-shadow: none;
    }
  }
}
