@component-namespace workspace-ov {
  @b card {
    box-shadow: 0px 6px 20px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 1.5rem;
    & .label {
      color: var(--text-dark-color);
      font-size: 16px;
      font-weight: bold;
    }
  }

  @b location {
    height: 9.375rem;
    padding: 1rem;
    & .panel-info {
      text-align: center;
      position: relative;
      padding: 2.5rem 0.625rem 0.625rem 18.75rem;
      & .status-labels {
        color: var(--text-dark-color);
        font-size: 0.75rem;
        display: flex;
        flex-wrap: wrap;
        text-align: left;

        & .label-item {
          flex: 50%;
        }
      }
    }
    & .panel-info.no {
      padding-left: 0px;
      text-align: left;
    }
    & a {
      text-decoration: underline;
    }
    & .graph {
      position: absolute;
      left: 0.625rem;
      top: 0.625rem;
      width: 5.625rem;
      height: 5.625rem;
    }
  }

  @b datasource {
    height: 9.375rem;
    padding: 1rem;
    & .panel-info {
      position: relative;
      padding: 1.875rem 0 0.625rem 6.875rem;
      & .desc {
        color: var(--grey-border-color);
        font-size: 0.75rem;
      }
      & strong {
        font-size: 1.25rem;
      }
    }
    & a {
      text-decoration: underline;
    }
    & .panel-info.no {
      padding-left: 0px;
      padding-top: 2.5rem;
    }
    & .graph {
      position: absolute;
      left: 0px;
      top: 0.625rem;
      width: 5.625rem;
      height: 5.625rem;
    }
  }

  @b title {
    color: var(--text-white-color);
    font-size: 1.4rem;
    white-space: nowrap;

    & .name {
      line-height: 100%;
      max-width: 16.5625rem;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
    }
  }

  /* @b name {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */

  @b badge {
    color: var(--text-white-color);
    background: linear-gradient(to top, #006a90, #009fbf);
    font-size: 0.75rem;
    font-weight: bold;
    padding: 1px 3px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.625rem;

    @s basic {
      border: 1px solid #fff;
      color: white;
      background: transparent;
      cursor: pointer;
      &:hover {
        background: white;
        color: #006b94;
      }
    }

    @s basic.link {
      cursor: pointer;
    }
  }

  @b id {
    font-size: 0.75rem;
    opacity: 0.5;
  }

  @b status-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: var(--grey-border-color);

    & .label {
      color: var(--accent-color);
      font-size: 1.5rem;
      font-family: Dosis;
    }
  }

  @b container {
    margin: 0.625rem 0;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */

    & .welcome-tip-panel {
      padding: 2rem 1.5rem;
      font-size: 0.75rem;
      color: var(--text-dark-color);
      position: relative;
    }
    & .welcome-tip-panel .dismiss-link {
      position: absolute;
      right: 2rem;
      top: 1.25rem;
      color: var(--accent-color);
      font-size: 0.75rem;
      cursor: pointer;
    }
    & .welcome-tip-panel h1 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  @b container-body {
    font-family: Nunito;

    &.empty {
      min-height: 30rem;
    }

    &.text-center {
      text-align: center;
    }

    @m title {
      color: var(--text-dark-color);
      font-size: 1.25rem;
      font-family: Dosis;
      font-weight: bold;
    }
    @m content {
      color: var(--grey-border-color);
      font-size: 0.75rem;
      margin: 0.625rem 0 1.25rem 0;
    }
  }

  @b location-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.25rem;
  }

  @b new-body {
    @m title {
      color: var(--text-dark-color);
      font-size: 1.25rem;
      font-weight: bold;
    }
    @m content {
      margin: 5rem 0 1.25rem 0;
    }
  }

  @b status {
    margin-bottom: 1.875rem;
    border: 1px solid var(--layout-border-color);

    @m label {
      font-size: 1rem;
      color: var(--text-dark-color);
      font-weight: bold;
    }

    @m tip {
      color: var(--grey-border-color);
    }

    @m content {
      margin-top: 1.25rem;

      &.empty {
        text-align: center;
      }
      &.empty svg {
        height: 6.25rem;
        width: 5.625rem;
      }
    }

    @m line {
      height: 0.75rem;
      cursor: pointer;
      transition: transform 0.5s;

      &:hover {
        transform: scaleY(1.7);
      }
    }

    @m line-wrap {
      display: flex;
      width: 100%;
      margin: 1rem 0;

      & .pop-inner {
        width: 6.125rem;
        text-align: center;
        cursor: pointer;
      }

      & .pop-num {
        color: var(--grey-border-color);
        font-size: 0.75rem;

        & strong {
          color: var(--text-dark-color);
          font-size: 1.25rem;
        }
      }
    }

    @m line-descwrap {
      display: flex;
    }

    @m line-desc {
      flex: 1;
      color: var(--text-dark-color);
      font-size: 0.75rem;
    }

    & .ant-progress {
      position: absolute;
      top: 0;
      left: 0;
    }

    @m card-num {
      font-size: 1.5rem;
      font-family: Dosis;
      font-weight: bold;
      line-height: 1.5rem;
      color: var(--text-dark-color);
    }
    @m card-num .all {
      font-size: 0.875rem;
      margin-left: 4px;
      font-weight: 400;
    }
    @m card-desc {
      font-size: 0.75rem;
      color: var(--grey-border-color);
    }
  }

  @b card {
    & ul li span + span {
      margin-left: 0.375rem;
    }

    & .date {
      font-size: 0.75rem;
      color: var(--grey-border-color);
    }

    & .title {
      font-size: 1rem;
      color: var(--text-dark-color);
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;
      font-weight: 700;
      border-bottom: 1px solid #efefef;
      display: flex;
      justify-content: space-between;
    }

    & .title .more {
      font-size: 0.75rem;
      font-weight: 400;
    }

    & ul {
      padding: 0 0.625rem;
    }

    & .activity-item-card {
      font-size: 0.875rem;
      margin-bottom: 0.625rem;
    }

    & .empty-tip {
      text-align: center;
      color: var(--grey-border-color);
    }

    & .noti-item-title {
      font-weight: 700;
    }

    & .noti-item-card {
      position: relative;
      padding-left: 2.5rem;
      font-size: 0.75rem;
      margin-bottom: 0.625rem;

      & .noti-item-icon {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.75rem;
        & .noti-tableIcon {
          margin: 0;
        }
      }
    }
  }

  @b activity {
    line-height: 1rem;
    font-size: 0.875rem;

    @e line {
      margin: 0.625rem 0;
      display: flex;
      justify-content: space-between;
      & span {
        margin: 0 3px;
      }
    }

    @e message {
      flex: 1;
    }

    @e name {
      color: #1e1e1e;
    }
    @e event {
      color: var(--text-grey-color);
    }
    @e type {
      color: var(--accent-color);
    }
    @e date {
      color: var(--text-grey-color);
      display: inline-block;
      width: 160px;
    }

    @e detail {
      padding: 1.125rem 1.375rem;
      background: #f8f8f8;
      & span {
        margin: 0 3px;
      }
    }

    @e id {
      margin: 0;
    }

    @e icon {
      color: var(--grey-border-color);
      margin-right: 5px;
      min-width: 0.875rem;
      min-height: 0.875rem;
      display: inline-block;
      &:hover {
        color: var(--accent-color);
      }
    }
  }
  @media (--only-mobile) {
    @b location {
      & .panel-info {
        padding-left: 60%;
        padding-top: 15px;
      }
      & .graph {
        left: 0;
      }
    }

    @b status {
      @m line-descwrap {
        flex-wrap: wrap;
      }
      @m line-desc {
        min-width: 90px;
      }
    }
  }
}
.dev-modal .form {
  margin-top: 0.625rem;
}
.dev-modal .submit-btn-wrap {
  margin-top: 0.625rem;
  text-align: right;
}
.submit-btn-wrap button + button {
  margin-left: 1.25rem;
}
.dev-modal .token-value {
  margin: 0.3125rem 0;
  padding: 0.625rem 0.9375rem;
  background: var(--layout-border-color);
}

.del-modal .cannot-del-ws-title {
  font-size: 1.25rem;
  color: var(--text-grey-color);
  font-weight: bold;
  margin-bottom: 0.9375rem;
}

.del-modal .cannot-del-ws-title span {
  margin-left: 0.625rem;
}

.del-modal .cannot-del-ws-p {
  margin-bottom: 0.9375rem;
}
.can-del-ws-title,
.edit-ws-titile {
  font-size: 1.875rem;
  color: var(--text-grey-color);
  font-weight: bold;
  margin-bottom: 0.9375rem;
}
.del-modal .can-del-ws-msg {
  margin-bottom: 1.875rem;
}
.del-modal .can-del-ws-footer button + button {
  margin-left: 1.875rem;
}
.edit-ws-footer button + button {
  margin-left: 1.875rem;
}
.edit-modal .input-group {
  margin-bottom: 1.25rem;
}

.label-dot {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  border: 2px solid transparent;
  margin-right: 5px;
}
