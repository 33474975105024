

@component-namespace people {
  @b search {
    border-radius: 4px;
    border: 1px solid #d7d7d7;
    flex: 1;

    & .ant-input {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 110px;
    }
  }

  @b header {
    padding-top: 17px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-self: center;

    @e search {
      flex: 1;
      position: relative;
    }

    @e dropdown {
      width: 110px;
      /* margin: 0 20px; */
      line-height: 44px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;

      & > i {
        font-size: 20px;
        margin-left: 5px;
        top: 4px;
      }
    }

    @e invite {
      height: 44px;
      line-height: 44px;
      border-radius: 4px;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
      border: 1px solid #d7d7d7;
      margin-left: 8px;
      transition: var(--base-transition);

      & > i {
        font-size: 15px;
        top: 3px;
      }

      &:hover {
        border-color: var(--accent-color);
      }

      @s disabled {
        border-color: var(--grey-border-color) !important;
      }
    }
  }

  @b list {
    margin: 20px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.17);
    border-radius: 4px;
  }

  @b accounts {
    padding: 14px 24px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @e message {
      & h4 {
        font-size: 14px;
        font-weight: 400;
        color: #1e1e1e;
        line-height: 19px;
        min-height: 19px;
      }

      & p {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-grey-color);
        line-height: 19px;
      }
    }

    @e action {
      cursor: pointer;

      & > i {
        font-size: 12px;
        margin-left: 16px;
        top: 1px;
      }
    }
  }

  @b accounts:last-child {
    border-bottom: none;
  }

  @b menu {
    @e item {
      font-weight: bold;
      width: 108px;
      text-align: center;
      position: relative;

      &.danger_text {
        color: var(--danger-color) !important;
      }

      & > i {
        font-size: 12px;
        position: absolute;
        left: 8px;
        top: 6px;
        font-weight: bold;
        color: var(--accent-color);
      }

      @s active {
        color: var(--accent-color);
        background: #f9fdff;
      }
    }
  }

  @b invite {
    @e input {
      margin: 15px 0;

      & .ant-input {
        padding-left: 35px !important;
      }
    }

    @e access > p {
      font-size: 14px;
      line-height: 19px;
      font-family: Nunito;
      font-weight: bold;
      color: var(--text-grey-color);
      margin-bottom: 6px;
    }
  }
}
