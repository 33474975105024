@component-namespace location {
  @b search {
    border: none;
    flex: 1;
    margin-right: 16px;

    @s block {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @b header {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    @e filter {
    }

    @e icons {
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;

      & path {
        fill: var(--accent-color) !important;
      }

      & #Rectangle {
        fill: white !important;
        stroke: var(--accent-color) !important;
      }

      & .st5 {
        stroke: var(--accent-color) !important;
      }
    }

    @e filter_item {
      width: 52px;
      line-height: 32px;
      vertical-align: middle;
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
      & svg {
        margin-right: 8px;
        height: 12px;
      }

      & > span {
        font-weight: bold;
      }

      & .ant-badge-count {
        background: var(--accent-color);
        /* left: 15px; */
        margin-right: -20px !important;
        margin-top: 5px !important;
      }

      & .current {
        color: var(--text-white-color);
      }
      @m filter {
        position: relative;
        padding-right: 30px;
        box-sizing: content-box;
        margin-right: 0;
      }
    }

    @e filter_text {
      position: relative;
      bottom: 2px;
    }
  }

  @b list {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  @b card {
    overflow: visible;
    margin-bottom: 20px;
  }

  @b filter {
    /* position: absolute; */
    /* right: 0; */
    /* top: 30px; */
    /* width: 120px; */
    /* z-index: 10; */
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); */
    background: var(--white-background-color);
    /* opacity: 0; */
    /* visibility: hidden; */
    transition: 0.2s ease all;

    @e search {
      width: 100%;
      padding: 0 8px;

      & .ant-menu-item-selected {
        background: none !important;
        &:after {
          border: none;
        }
      }

      & .ant-select-selection {
        border-radius: 0;
        border: none;
        box-shadow: none !important;
      }

      & .ant-select-arrow {
        right: 8px;
      }
    }

    @e search_menu {
      &.ant-menu-item[role="menuitem"] {
        height: 120px !important;
      }

      &.ant-menu-item-selected {
        background: none !important;

        &:after {
          border: none;
        }
      }

      &:hover {
        background: none !important;
      }

      & .ant-select-dropdown-menu-item {
        right: 8px;
      }
    }

    @e dropdown {
      background: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      & ul {
        height: 80px;
        overflow: scroll;
        margin: 0 1px;
      }

      & .ant-select-dropdown-menu-item {
        font-size: 12px;
        line-height: 16px;
        color: var(--grey-border-color);
      }
    }
  }

  @b filter {
    & .ant-menu-submenu-title {
      padding: 0 16px !important;
      line-height: 32px !important;
      height: 32px !important;
      margin: 0;
      font-weight: bold;
      color: var(--text-dark-color);
      font-size: 0.75rem;
      line-height: 1rem;

      &[aria-expanded="true"] {
        background: var(--secondary-background-light);
      }
    }

    & .ant-menu-item {
      padding: 0 !important;
      margin: 0 !important;
      height: 32px !important;
      line-height: 32px !important;

      &:hover {
        background: var(--secondary-background-light);
      }

      &.ant-menu-item-selected:after {
        font-family: "Material Icons";
        font-weight: normal;
        /* background: var(--accent-color);
        color: var(--white-background-color);
        border-radius: 50%; */
        width: 16px;
        height: 16px;
        content: "check_circle" !important;
        font-style: normal;
        cursor: default;
        font-size: 1rem; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        cursor: pointer;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: "liga";
        border: none;
        content: "check_circle";
        right: 16px;
        top: 8px;
        transition: none;
      }
    }

    & .ant-menu-item-selected {
      background-color: var(--white-background-color) !important;

      & .menu_text {
        color: var(--accent-color);
        font-weight: bold;
      }
    }

    & .menu_text {
      text-align: left;
    }

    & .ant-select-selection__rendered {
      margin: 0;
      padding: 0 8px;
      font-size: 12px;
      border-bottom: 1px solid var(--grey-border-color);
    }

    & .ant-select-selection__placeholder {
      padding-left: 8px;
    }
  }

  @b filter_condition {
    margin: 30px 0;
    /* min-height: 30px; */

    @e item {
      height: 30px;
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 6px;
      border-radius: 30px;
      background: #f4f4f4;
    }

    @e close {
      width: 30px;
      display: inline-block;
      border-right: 1px solid #e2e2e2;
      text-align: center;
      cursor: pointer;
    }

    @e text {
      display: inline-block;
      text-align: center;
      line-height: 30px;
      padding: 0 10px;
      font-size: 12px;
      color: var(--text-dark-color);
    }
  }

  @media (--only-mobile) {
    @b header {
    padding: 0 1rem;
    margin-top: 17px;
      @e filter_item {
        margin-right: 10px;
        @m filter {
          padding-right: 10px;
        }
      }
    }
    @b list {
      padding: 0 1rem 1rem 1rem;
    }
    @b card {
      margin-bottom: 35px;
    }
  }
}
