@component-namespace kiosk {
  @b modal {
    width: 70vw;
    flex: 1 1 70vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    @e background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: -1;
      background-position: center center;
    }
  }
  @b level {
    text-transform: uppercase;
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
    &.level-moderate {
      color: #e2e830 !important;
    }
    &.level-good {
      color: #7bd865 !important;
    }
    &.level-excellent {
      color: #7bd865 !important;
    }
    &.level-sensitive {
      color: #faad5b !important;
    }
    &.level-unhealthy {
      color: #f36d3c !important;
    }
    &.level-very-unhealthy {
      color: #d93127 !important;
    }
    &.level-hazardous {
      color: #4f0004 !important;
    }
  }

  @b indicator-name {
    color: var(--kiosk-indicator-color);
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  @b indicator-value {
    color: var(--kiosk-indicator-color);
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
    max-width: 3rem;
    font-family: Dosis;
  }
  @b indicator-unit {
    display: inline-block;
    margin-left: 3px;
    width: 30px;
    text-align: left;
    line-height: normal;
    font-size: 10px;
    color: var(--kiosk-indicator-unit);
  }
  @b data-name {
    color: var(--kiosk-indicator-unit);
    font-size: 14px;
    text-transform: uppercase;
  }

  @b body {
    position: fixed;
    top: 3.3125rem;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @b kiosk-bodyV3 {
    position: fixed;
  }

  @b blurred-element {
    filter: blur(5px);
    /* backgroundColor: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), */
  }

  @b bodyV3 {
    position: fixed;
    top: 3.3125rem;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media screen and (max-width: 900px) {
    @b body {
      position: static;
    }
    @b bodyV3 {
      position: static;
    }
    @b kiosk-bodyV3 {
      position: static;
    }

    @b body {
      position: static;
      padding-top: 0rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @b bodyV3 {
      display: static;
      padding-top: 0rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @b page {
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
    }

    @m group-panel-left {
      zindex: 1;
      padding: 2vh 3vw 2vh 4vw;
      @media screen and (max-width: 900px) {
        width: 60vw;
        margin: auto;
      }
      @media screen and (min-width: 901px) {
        height: 100vh;
        padding: 2vh 3vw 2vh 4vw;
        background: rgba(192, 192, 192, 0.6);
        width: 31vw;
      }
      @media screen and (min-width: 1100px) {
        width: 25vw;
        padding: 2.5vh 2vw 2vh 3vw;
      }
    }
    @m group-panel-right {
      margin: 0 1px;
      padding: 0.1vh 0.1vw;
    }

    @m panel-title {
      font-size: 3vh;
      font-family: Nunito;
      font-weight: bold;
      margin: 1vh 0 1vh;
      line-height: 3vh;
      color: black;
    }

    @m transmission-risk {
      font-family: Nunito;
    }

    @m last-ts {
      color: #9b9b9b;
      font-size: 1.2vw;
      margin-top: 48px;
    }

    @m practice-index {
      font-family: Nunito;
      font-size: 1.2vw;
      color: #4a4a4a;
      & .index-row {
        display: flex;
        padding: 0.4vh 1.2vw 0.4vh 1.2vw;
        margin: 1vh 0 1vh 0;
        background: rgba(241, 245, 251, 0.8);
        border-radius: 1.2vw;
      }

      & .index-detail-row {
        display: flex;
        /* margin-top: 37px; */
        padding: 1vh 1.2vw 1vh 1.2vw;
        margin: 1.2vh 0 1.2vh 0;
        background: rgba(241, 245, 251, 0.8);
        border-radius: 1.2vw;
        justify-content: space-between;
      }
      @media screen and (min-width: 600px) {
        & .elipseSize {
          width: 4.2vh;
          height: 4.2vh;
        }
      }
      & .index-name {
        flex: 0 0 5px;
        margin-right: 5px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 900;
        font-size: 2vh;
        line-height: 2.5vh;
        color: #14121e;
      }
      & .index-detail-name {
        flex: 0 0 2px;
        margin-right: 4px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 900;
        font-size: 2.2vh;
        line-height: 2.8vh;
        color: black;
        /* @media screen and (max-width: 1000px) {
          font-size: 14px;
          font-weight: 700;
          margin-right: 1px;
        } */
      }
      & .index-progress {
        flex: 0 0 5px;
      }
      & .ant-progress-inner {
        border: solid black 0.3px;
        width: 100%;
      }
      & .index-value {
        flex: 1;
        font-family: Nunito;
        font-style: normal;
        font-weight: 900;
        font-size: 3vh;
        line-height: 3vh;
        color: #14121e;
      }
      & .index-value-indicator {
        font-style: bolder;
        font-size: 2vh;
      }
      & .index-detail-value {
        flex: 1;
        font-family: Nunito;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8vh;
        line-height: 1.8vh;
        color: black;
      }
    }

    @media screen and (max-width: 900px) {
      @m practice-index {
        font-family: Nunito;
        font-size: 16px;
        color: #4a4a4a;
      }
      & .panel-shadow {
        margin: auto;
      }
    }

    @media screen and (max-width: 600px) {
      @m practice-index {
        font-family: Nunito;
        font-size: 12px;
      }
      & .elipseSize {
        width: 3.5vh;
        height: 3.5vh;
      }
      & .index-name {
        font-family: Nunito;
        font-size: 12px;
        color: #4a4a4a;
      }
      & .index-value {
        font-family: Nunito;
        font-size: 12px;
        color: #4a4a4a;
      }

      & .panel-shadow {
        margin: auto;
        /* width: 90%; */
      }
    }

    @e video {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @s pwd {
      & .kiosk-page--container-with-background {
        display: block;
        /* position: absolute;
        left: 30px; */
      }
    }

    @m summary .flex-h {
      display: flex;
      padding: 0.4rem 0.6rem;
      justify-content: space-between;
    }

    @m container-with-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: -1;
      background-position: center center;
      display: none;

      & svg {
        width: 100%;
        height: 100%;
      }
    }

    @m content-modal {
      width: 100%;
      flex: 1 1 70vw;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      padding: 8vh 30px 30px 30px;

      @s full {
        width: 70vw;
      }
    }

    @m modal-header {
      color: #636466;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #979797;
      padding-bottom: 20px;
    }

    @m modal-body {
      display: flex;
      flex-wrap: wrap;
    }

    @m floating-menu {
      position: fixed;
      bottom: 10px;
      right: 10px;
      & > span {
        display: block !important;
      }
    }
    @m floating-menu .ant-btn {
      line-height: 1;
      margin-top: 10px;
      display: block;
      color: var(--kiosk-indicator-color) !important;
      & path {
        fill: var(--kiosk-indicator-color);
      }
    }
    @m floating-menu .top-icon {
      vertical-align: top;
      font-size: 22px;
    }

    @m close-btn {
      position: absolute;
      top: 7vh;
      right: 10px;
      font-size: 32px;
      padding: 5px;
      cursor: pointer;
    }
    @m close-btn:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }

    @m summary {
      padding: 0;
      @media screen and (max-width: 900px) {
        padding: 0;
      }
      width: 30vw;
      flex: 1 1 30vw;
      height: 100%;
      min-width: 360px;
      max-width: 450px;
      background-color: var(--kiosk-background-color);
      overflow: hidden;
      color: var(--kiosk-indicator-color);
      text-align: center;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.5);
      font-family: Nunito;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.26);
        border-radius: 20px;
      }

      & .collection-selector .ant-select-selection {
        color: var(--kiosk-indicator-color);
        background: transparent;
      }

      & .location-logo {
        padding: 1rem 1.2rem 0.5rem 1.2rem;
      }

      & .location-logo img {
        max-width: 250px;
        max-height: 38px;
      }

      & .summary-selector {
        margin-top: 15px;
      }

      & .summary-update {
        font-size: 13px;
        text-align: right;
      }

      & .current-ts {
        margin-right: 30px;
      }

      & .location-name {
        padding: 0 1.6rem;
      }

      & .location-name h2 {
        font-size: 1.2rem;
        color: var(--kiosk-indicator-color);
      }

      & .summary-location {
        font-size: 13px;
      }

      & .summary-location span {
        margin-left: 5px;
      }
    }
  }

  @b page.v2 {
    /* width: 1232px; */
    height: auto;
    margin: 0px auto;
    padding: 0px 0 0 0;

    @media screen and (max-width: 900px) {
      background: no-repeat
        linear-gradient(rgba(192, 192, 192, 0.6), rgba(192, 192, 192, 0.6));
    }
  }

  @b permision-dialog {
    width: 488px;
    height: 300px;
    margin: 0 auto;
    margin-top: 120px;
    background: var(--white-background-color);
    box-shadow: 0px 5px 4px 0px rgba(181, 181, 181, 0.31);
    padding: 32px;

    & .location-name {
      font-size: 26px;
      color: var(--text-dark-color);
      font-family: Dosis;
      font-weight: 600;
    }

    & .pwd-tip {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 12px;
      color: var(--grey-border-color);
    }
  }

  @b btn-group {
    text-align: right;
    margin-top: 30px;
  }

  @b btn-group .link {
    display: inline-block;
    margin-right: 15px;
  }

  @b btn-group button {
    width: 150px;
  }

  @b input-group {
    margin-top: 40px;
  }
  @b modal-info {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-width: 90vw;
    width: 70%;
    padding: 1.5rem;
    box-sizing: border-box;
  }

  @b modal-info-content {
    margin-top: 1rem;
    max-height: 70vh;
    overflow-y: auto;
  }

  @b page--close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    font-size: 1.25rem;
    background: none;
    border: none;
    color: var(--kiosk-close-icon-color, #333);
  }

  @b modal-info-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

@media (--only-not-desktop) {
  @component-namespace kiosk {
    @b modal-info {
      width: 90vw;
      max-width: none;
      max-height: 80vh;
      padding: 1rem;
    }

    @b page--close-btn {
      font-size: 1.5rem;
    }
  }


}

@component-namespace collection-item {
  @b card {
    flex: 0 1 30%;
    margin: 10px;
    width: 30%;
    cursor: pointer;
    background: var(--white-background-color);

    @m name-box {
      display: flex;
      background-color: rgba(245, 245, 245, 1);
      padding: 4px 15px;
      line-height: 12px;
      justify-content: space-between;
      & .stale-label {
        color: white;
        background: var(--danger-color);
        font-weight: bold;
        padding: 0 6px;
        height: 18px;
        line-height: 18px;
        border-radius: 5px;
        margin-right: -7px;
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    @m name {
      color: #636466;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @m body {
      font-family: Dosis;
      padding: 8px 15px;
      min-height: 91px;
    }
    @m body label {
      color: var(--text-grey-color);
      font-size: 14px;
      font-weight: 600;
    }
    @m indicators {
      display: flex;
    }
    @m indicators .indicator {
      flex: 1;
    }
    @m indicators .indicator-label {
      font-size: 12px;
      color: var(--grey-border-color);
    }
    @m indicators .indicator-value {
      color: var(--text-grey-color);
      font-size: 20px;
      font-weight: 600;
    }
    @m footer {
      text-align: center;
      font-size: 12px;
      font-family: Dosis;
      font-weight: bold;
      color: var(--primary-color);
      line-height: 23px;
      text-transform: uppercase;
    }
    @m footer.offline {
      background: #999;
    }
    @m footer.good {
      background: var(--status-good-color);
    }
    @m footer.moderate {
      background: var(--status-moderate-color);
    }
    @m footer.sensitive {
      background: var(--status-sensitive-color);
    }
    @m footer.unhealthy {
      background: var(--status-unhealthy-color);
    }
    @m footer.very_unhealthy {
      background: var(--status-very-unhealthy-color);
    }
    @m footer.hazardous {
      background: var(--status-hazardous-color);
    }
  }
}

@component-namespace primary {
  @b reading {
    position: relative;
    margin: 1.6rem 1rem 0 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px var(--kiosk-underline-color) solid;

    @m header {
      position: absolute;
      top: 0;
      left: 0;
    }

    @m body {
      font-size: 7rem;
      line-height: 7rem;
      font-family: Dosis;
      color: var(--kiosk-indicator-color);
    }

    @m unit {
      position: absolute;
      right: 0;
      top: 6rem;
      font-size: 0.8rem;
      color: var(--kiosk-indicator-unit);
    }
    @m data-wrap {
      margin-top: 1rem;
    }
    @m data-row {
      display: flex;
      font-weight: 400;
      align-items: center;

      & .left {
        flex: 1 1 auto;
        text-align: right;
        text-transform: uppercase;
      }

      & .stale-label {
        color: white;
        background: var(--danger-color);
        font-weight: bold;
        padding: 0 6px;
        height: 18px;
        line-height: 18px;
        border-radius: 10px;
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }

      & .right {
        flex: 0 1 4rem;
        text-align: left;
        margin-left: 1.4rem;
        white-space: nowrap;
      }
    }
  }
}

@component-namespace second {
  @b reading {
    @m data-wrap {
      margin: 0 1rem;
      padding: 0.6rem 0;
      border-bottom: 1px var(--kiosk-underline-color) solid;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (--only-not-desktop) {
  @component-namespace kiosk {
    @b modal {
      position: absolute;
      width: 100%;
      z-index: -1;

      @e background {
        display: none;
      }
    }

    @b page {
      @m summary {
        width: 100vw;
        height: 95vh;
        max-width: none;
        background-color: rgba(0, 0, 0, 0.6);
      }

      @m content-modal {
        position: absolute;
        width: 100% !important;
      }

      @m graph .graph-header {
        flex-wrap: wrap;
      }

      /* @m graph .graph-dropdown {
        flex: 0 1 100%;
      } */

      @m graph .report-bar-header {
        padding: 0 10px;
      }

      /* @m graph .graph-dropdown + .graph-dropdown {
        margin-top: 40px;
      } */

      @m summary .summary-header {
        background-color: var(--kiosk-background-color);
      }

      @m container-with-background {
        display: block !important;
      }
    }
  }
  @component-namespace collection-item {
    @b card {
      flex: 0 1 100%;
    }
  }
}

.collection-selector--dropdown .ant-select-dropdown-menu-item {
  color: var(--kiosk-indicator-color) !important;
  background: var(--kiosk-background-color) !important;
}

.reading-row-with_time {
  margin: 0 1rem;
  padding: 0.6rem 0;
  border-bottom: 1px var(--kiosk-underline-color) solid;
}

.ResetViralBeta {
  font-family: Nunito;

  font-style: normal;
  font-weight: 900;
  font-size: 4vh;
  line-height: 3.5vh;
  text-align: center;
  color: black;
  padding: 0 0 0.5vh 0;
  @media screen and (max-width: 970px) {
    font-size: 29px;
  }
  @media screen and (max-width: 900px) {
    padding: 2vh 0 0 0;
  }
  }
.ResetViralTitle {
  font-family: Nunito;
  padding-top: 1.5vh;
  font-style: normal;
  font-weight: 900;
  font-size: 4vh;
  line-height: 3.5vh;
  text-align: center;
  color: #006b94;
  @media screen and (max-width: 970px) {
    font-size: 29px;
  }
}
.ResetViralOfficeName {
  padding: 2.5vh 0 1.5vh 0;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 4vh;
  line-height: 3.5vh;
  text-align: center;
  color: #14121e;
}
.ResetViralOfficeCity {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 3vh;
  line-height: 2.2vh;
  text-align: center;
  color: #14121e;
}

.hoveredPopup {
  position: absolute;
  text-align: center;
  margin: 60px 0 0 20px;
  background-color: white;
  box-shadow: 2px 2px 2px;
  opacity: 0.7;
}
.LastUpdateCentered {
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;

  font-size: 1.5vw;
  line-height: 1.7vw;
  color: #14121e;
}
.empty-data-template {
  height: 40vh;
}

.LastUpdateCenteredTime {
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 1.9vw;
  line-height: 2.1vw;
  color: #14121e;
}

@media screen and (max-width: 650px) {
  .ResetViralTitle {
    font-size: 4.4vw;
  }
  .ResetViralOfficeName {
    font-size: 3.7vw;
    padding: 1vw;
  }
  .ResetViralOfficeCity {
    font-size: 3vw;
    padding: 0.5vw;
  }
  .LastUpdateCentered {
    font-size: 4vw;
    line-height: 4.5vw;
  }
  .LastUpdateCenteredTime {
    line-height: 4vw;
    font-size: 4vw;
  }
}

@component-namespace kiosk {
  @media (--only-mobile) {
    @b page {
      height: calc(100vh - 48px);
      @m group-panel-left {
          width: 100%;
          padding: 0 1rem;
        }
      @m content-modal {
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.95);
      }
      @m group-panel-right {
        margin: 0px;
        padding: 0px;
      }
      @m floating-menu {
        z-index: 999;
        right: 50px!important;
        top: 7px!important;
        display: inline-flex;
        height: 35px;
        width: 200px;
        left: unset!important

      }
      @m floating-menu .ant-btn {
        margin-top: 0px;
        margin-right: 15px;
        width: 35px;
        height: 35px;
        border: solid white 1px;
      }
    }
    @b modal {
      z-index: auto;
    }
    @b page.v2 {
      min-height: 100vh;
    }
    .inKiosk {
      padding: 0rem!important;
    }
    .empty-data-template {
      height: auto;
    }
    .summary-header {
      position: relative;
      z-index: 1;
    }
    .kiosk-page--content-modal.is-full {
      z-index: 2;
    }
    .index-logo {
      margin-left: 10px;
      margin-right: 15px!important;

    }
  }
}
