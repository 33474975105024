@component-namespace nodata {
  @b container {
    padding: 20px;
    text-align: center;

    & > img {
      max-width: 100%;
      margin-bottom: 37px;
    }
  }

  @b text {
    margin-bottom: 16px;
  }

  @b tip {
    font-size: 16px;
    line-height: 25px;
  }
}
