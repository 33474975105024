

@component-namespace edit {
  @b icon {
    color: var(--accent-color);
    font-size: 0.75rem;
    margin-right: 5px;
    vertical-align: middle;
  }

  @b disabled {
    @e text {
      cursor: not-allowed;
      margin-right: 4px;
      color: var(--text-dark-color);
      padding: 5px 10px;
      font-weight: bold;
      display: inline-block;
      font-size: 24px;
      line-height: 32px;
      font-family: Nunito;
    }
  }
}
