.status {
  border-radius: 1px;
  color: white;
  line-height: 20px;
  width: 56px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  font-family: Dosis;
  text-transform: uppercase;
}
.status.large {
  width: 80px;
  line-height: 24px;
  border-radius: 4px;
}
.online {
  background-color: #4a90e2;
}
.flatline {
  background-color: #faad5b;
}
.offline {
  background-color: #a6a6a6;
}
.second {
  color: var(--grey-border-color);
}
.primary {
  margin-right: 5px;
}
.icon-l {
  margin-left: 5px;
}
.link {
  color: #0b75a9;
}
.clickable {
  cursor: pointer;
}
