@component-namespace upgrade {
  @b content {
    & p {
      margin-bottom: 15px;
      line-height: 1.5;
    }
    & a {
      text-decoration: underline;
    }
    & ol {
      padding-left: 20px;
      margin-bottom: 15px;
      color: var(--accent-color);
      font-size: 18px;
      line-height: 24px;
    }

    & ol.down {
      color: var(--danger-color);
    }

    & .input-workspace-name {
      margin: 30px 0;
    }
  }
  @b container {
    margin-top: 64px;
    padding: 50px;
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05),
      0px 0px 2px 2px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    border: 1px solid rgba(237, 237, 237, 1);

    & .bot-action {
      margin-top: 50px;
    }

    @e title {
      font-size: 24px;
      font-family: Nunito;
      font-weight: bold;
      /* color: var(--text-dark-color); */
      line-height: 32px;
      text-align: center;
      margin-bottom: 40px;
    }

    @e line {
      width: 120px;
      height: 2px;
      margin-top: 24px;
      margin-bottom: 72px;
      background: var(--accent-color);
    }
  }

  @b input {
    display: flex;
    margin-top: 24px;
    & .ant-input {
      border-radius: 4px;
      margin-right: 16px;
    }

    & .ant-btn {
      min-width: 120px;
      border-radius: 4px;
    }
  }
}

@component-namespace pricing {
  @b list {
    padding: 48px 0;
    overflow: hidden;
    transition: var(--base-transition);
    /* max-height: 0; */
    width: 100%;
    /* opacity: 0; */
    /* overflow-y: hidden; */

    @e button {
      text-align: center;
      margin-bottom: 2.5rem;
    }
    @e button span {
      width: 5.625rem;
    }
    @e button + p {
      text-align: center;
    }
    @e button + p > button {
      min-width: 9.1875rem;
    }

    @e col {
      padding-left: 0.625rem !important;
      padding-right: 0.625rem !important;
    }

    @e card {
      padding-top: 2.625rem;
      padding-bottom: 2.625rem;
      /* padding-left: 4.375rem; */
    }

    @e head {
      text-align: center;
      margin-bottom: 2.5rem;
    }
    @e head h1 {
      font-size: 4.5rem;
    }

    @e head .common-line {
      width: 5.625rem;
    }

    @e price {
      position: relative;
    }

    @e price:before {
      content: "$";
      color: var(--input-border-color);
      font-family: "PingFang TC";
      font-size: 2.25rem;
      font-weight: 500;
      line-height: 3.125rem;
      position: absolute;
      left: -50%;
      top: 0;
    }

    @e message {
      margin-top: 0.5rem;
      margin-bottom: 2.5rem;
    }

    @e content {
      padding-left: 60px;
    }

    @e part {
      margin-bottom: 2.5rem;
    }

    @e item {
      margin-top: 0.5rem;
      min-height: 27px;
      @s check:before {
        content: "check";
        font-family: "Material Icons";
        font-weight: bold;
        font-style: normal;
        font-size: 1rem; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: "liga";
        margin-right: 2rem;
        vertical-align: middle;
      }

      @s disabled {
        color: var(--text-grey-color);
        opacity: 0.3;
      }
      @s disabled:before {
        content: "";
        background: var(--text-grey-color);
        opacity: 0.3;
        margin-right: 2rem;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 2px;
      }

      @s highlight:before {
        content: "check";
        font-family: "Material Icons";
        font-weight: bold;
        font-style: normal;
        font-size: 1rem; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: "liga";
        margin-right: 2rem;
        vertical-align: middle;
        color: var(--accent-color);
      }

      @s bold {
        font-weight: bold;
        color: var(--accent-color);
      }
      @s bold:before {
        content: "check";
        font-family: "Material Icons";
        font-weight: bold;
        font-style: normal;
        font-size: 1rem; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: "liga";
        margin-right: 2rem;
        vertical-align: middle;
        color: var(--accent-color);
      }
    }

    @e subItem {
      text-indent: 3rem;
      @s disabled {
        color: var(--text-grey-color);
        opacity: 0.3;
      }

      @s bold {
        font-weight: bold;
      }
    }

    @e subItem:before {
      content: "- ";
    }
  }
}

.pricing-card-tip.is-hightlight:after {
  border-top-color: #24576b;
}

.pricing-card.is-selected {
  & .pricing-card-tip {
    top: -0.5rem;
  }
}

.common-line {
  background: var(--accent-color);
  height: 2px;
  display: inline-block;
}
