@component-namespace indicator {
  @b active {
    @e title {
      margin: 10px 0;
    }

    @e count {
      font-size: 12px;
      line-height: 16px;
      margin: 0 15px;

      & > b {
        font-size: 16px;
        vertical-align: middle;
      }
    }

    @e search {
      display: flex;
      margin: 8px 0;
      & .ant-btn {
        border-radius: 2px;
        margin-left: 8px;
      }
    }

    @e searchbar {
      flex: 1;
      border-radius: 2px;
    }
  }

  @b table_badge {
    position: absolute;
    margin-left: -12px;
  }

  @b disable_row {
    cursor: pointer;
    & span {
      color: var(--grey-border-color) !important;
    }
    & td {
      color: var(--grey-border-color) !important;
    }
  }
}
