@component-namespace collection {
  @b indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @b icon {
    color: var(--grey-border-color);
    font-size: 12px;
    margin-right: 3px;
    position: relative;
    bottom: -3px;
    @m play {
      margin: -5px 5px 0 5px;
      bottom: -4px;
    }
  }

  @b remove {
    float: right;
    margin-right: 200px;
    margin-left: 5px;
    &:hover {
      color: var(--danger-color);
    }
  }

  @b name {
    display: inline-block;
  }

  @b svg {
    width: 20px;
    height: 20px;
    float: left;
    margin-left: -5px;
    margin-top: -3px;
    margin-right: 12px;

    @s title {
      margin-right: 0px;
    }
  }

  @b content {
    width: calc(100% - 2px);
    & .ant-table-pagination.ant-pagination {
      margin-right: 20px;
    }
    & .custom-table {
      border: 1px solid var(--layout-border-color);
    }
  }

  @b note {
    @e textArea {
      width: 270px !important;
      resize: none;

      &.ant-input-disabled {
        background: transparent;
      }
    }

    @e edit {
      margin-left: 25px;
      cursor: pointer;
      color: var(--accent-color);
    }
  }

  @media (--only-mobile) {
    @b remove {
      margin-right: 40px;
    }
    .hidden-mobile {
      display: none;
    }

  }
}
