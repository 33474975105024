@component-namespace colletion_modal {
  @b wrap {
    padding: 1.5rem 2.5rem;

    & .ant-tabs-tabpane {
      min-height: 200px;
    }

    & .form-label {
      margin-bottom: 5px;
      color: var(--text-dark-color);
      font-size: 12px;
    }
    & .tip {
      font-size: 12px;
      margin-bottom: 5px;
      color: var(--text-dark-color);
    }

    & .ant-tabs-nav .ant-tabs-tab {
      padding-left: 0;
      padding-right: 0;
    }

    & .form-group {
      margin-bottom: 10px;
    }
    & .datasource-panel + .datasource-panel {
      margin-top: 15px;
    }

    & .datasource-panel {
      border-radius: 4px;
      border: 1px solid rgba(221, 221, 221, 1);
      padding: 10px 16px;
      position: relative;

      & .label {
        font-size: 12px;
        color: var(--grey-border-color);
        margin-bottom: 4px;

        & .link {
          margin-left: 10px;
        }
      }

      & .header-action {
        text-align: right;
        height: 20px;
        margin-top: -5px;
        margin-right: -5px;
        color: #cacaca;
        cursor: pointer;

        & i:hover {
          color: red;
        }
      }

      & .ant-select {
        width: 100%;
      }
    }
  }

  @b calibration {
    padding: 24px 0;

    @e row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      @s text {
        margin-top: -8px;
        margin-bottom: 0;
      }
    }

    @e label {
      width: 200px;
      margin-right: 30px;
      font-size: 14px;
      text-align: right;
      color: var(--text-dark-color);
    }

    @e content {
      width: 300px;
      vertical-align: middle;
    }

    @e select {
      width: 100%;
    }

    @e factor {
      width: 156px;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;

      & .collection-add_text {
        margin-left: -8px;
        font-weight: normal;
      }

      @s notification {
        width: 113px;

        & .ant-btn {
          height: 26px;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 26px;
        }
      }
    }

    @e value {
      width: 63px;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;

      @s notification {
        width: 70px;
      }
    }

    @e delete {
      width: 20px;
      font-size: 20px;
      position: relative;
      bottom: -4px;
    }

    @e button_group {
      text-align: center;
      margin-top: 8px;

      & button {
        width: 136px !important;
        border-radius: 2px !important;
      }
    }
  }

  @b container {
    padding: 1.5rem 2.5rem;
  }

  @b button_group button {
    height: 24px;
    font-size: 12px !important;
  }

  @b data_source {
    padding: 0 38px;
    font-size: 12px;

    @e title {
      height: 31px;
      color: var(--grey-border-color);
      font-family: Dosis;
      font-weight: bold;
      line-height: 31px;

      & > span {
        margin-right: 5px;
      }
      @s channel {
        border-top: 1px solid var(--layout-border-color);
        border-bottom: 1px solid var(--layout-border-color);
      }
    }

    @e title_id {
      color: var(--text-dark-color);
      margin-left: 5px;
    }

    @e change {
      float: right;
      margin-right: 40%;
      color: var(--accent-color);
    }

    @e row {
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 38px;
      cursor: pointer;

      @s title {
        background: var(--secondary-background-light);
        & > div {
          font-weight: bold;
        }

        & .colletion_modal-data_source-field--unassign {
          text-align: center;
        }
      }

      &:hover {
        background: var(--secondary-background-light);
      }
    }

    @e field {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;

      @m uuid {
        flex: 1;
        min-width: 120px;
        font-weight: 500;

        & > span {
          margin-left: 8px;
        }
      }

      @m brand {
        width: 150px;
        margin: 0 10px;
      }

      @m identifier {
        width: 250px;
        margin: 0 10px;
      }
      @m unassign {
        width: 140px;
        text-align: right;
      }

      @m parameter {
        width: 100px;
        color: var(--grey-border-color);
      }

      @m unit {
        width: 80px;
        color: var(--grey-border-color);
      }

      @m collection_name {
        width: 200px;
      }

      @m collection_parameter {
        flex: 1;
        margin: 0 10px;

        & > span {
          color: var(--accent-color);
          margin: 0 5px;
        }

        & > .grey_text {
          color: var(--grey-border-color);
        }
      }
    }
  }

  @b search .ant-input {
    padding-left: 38px !important;
  }

  @b list {
    min-height: 180px;
  }

  @b footer {
    display: flex;
    margin-top: 30px;

    & .ant-btn {
      flex: 1;
    }

    & .ant-btn + .ant-btn {
      margin-left: 40px;
    }
  }
}
