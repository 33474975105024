

@component-namespace search {
  @b wrapper {
    margin: 40px 15px;
  }
  @b result {
    border-bottom: 1px solid var(--layout-border-color);
    @e title {
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      padding: 16px 0;
      color: var(--text-dark-color);
    }
    @e line {
      width: 90px;
      margin: 16px 0;
      border-bottom: 2px solid var(--accent-color);
    }

    @e list {
      padding: 16px 0;
      & a {
        color: var(--accent-color);
      }

      & > li {
        padding: 8px 0;
        font-weight: bold;
        font-size: 14px;
        font-family: 'Nunito';
        color: var(--accent-color);
        line-height: 19px;
      }
    }

    @e uuid {
      display: inline-block;
      width: 350px;
    }
    @e name {
    }
  }

  @b result:last-child {
    border-bottom: none;
  }

  @b nodata {
    @e container {
      margin-top: 80px;
      padding: 50px 0;
      text-align: center;
      border-radius: 2px;
      border: 1px dashed var(--grey-border-color);

      & h2 {
        font-size: 20px;
        line-height: 26px;
        color: var(--text-dark-color);
        margin-bottom: 8px;
      }

      & p {
        font-size: 12px;
        color: var(--grey-border-color);
        line-height: 18px;
      }
    }

    @e search {
      margin-bottom: 32px;
    }
  }
}
