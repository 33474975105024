@component-namespace locationcard {
  @b container {
    width: 200px;
    height: 255px;
    cursor: pointer;
    background: var(--white-background-color);
    margin: 0;
    border-radius: 4px;
    transition: box-shadow 0.1s ease;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }

  @b container.public {
    height: auto;
  }

  @b title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px 10px;
    font-weight: 600;
  }

  @b background {
    height: 120px;
    overflow: hidden;
    position: relative;

    @e img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @e loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & > p {
        margin: 5px 0;
      }
    }

    @e title {
      position: absolute;
      z-index: 5;
      bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: var(--text-white-color);
      padding: 0 16px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @b type {
    height: 25px;
    border-top: 1px solid var(--layout-border-color);
    border-bottom: 1px solid var(--layout-border-color);
    background-color: #f5f5f5;
    overflow: hidden;
    padding-left: 10px;
    vertical-align: middle;

    & > span {
      color: var(--text-dark-color);
      text-transform: uppercase;
      min-width: 35px;
      margin-left: -5px;
    }

    & img {
      height: 14px;
    }

    @e ability {
      width: 80px;
      display: inline-flex;
      height: 16px;
      justify-content: space-around;
      align-items: center;
      border-left: 1px solid var(--layout-border-color);
      border-right: 1px solid var(--layout-border-color);
      position: relative;
      top: 2px;
      margin: 0 8px;

      @s location {
        width: 40px;
        border-left: none;
        border-right: none;
        margin: 0;
      }
    }
  }

  @b indicator {
    padding: 7px 10px;
    height: 85px;
    @e title {
      color: var(--text-grey-color);
      white-space: nowrap;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      @s short {
        width: 115px;
      }
    }

    @e status {
      color: var(--accent-color);
      text-align: right;
      float: right;
      margin-top: 2px;
      margin-right: -5px;
    }

    @e row {
      display: flex;
      margin-left: -7px;
      margin-right: -7px;
    }

    @e item {
      margin-top: 5px;
      text-align: center;
      flex: 1 1 auto;
      min-width: 55px;

      &:not(:nth-child(1)) {
        & > .locationcard-indicator-value {
          border-left: 1px solid var(--layout-border-color);
        }
      }
    }

    @e name {
      font-size: 12px;
      color: var(--grey-border-color);
      line-height: 15px;
      padding: 0 7px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }

    @e value {
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      padding: 0 7px;
      font-family: Dosis;
    }
  }

  @b status {
    height: 25px;
    border-radius: 0 0 4px 4px;
    color: var(--text-white-color);
    font-family: Dosis;
    font-size: 12px;
    font-weight: bold;
    line-height: 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-transform: capitalize;
    text-align: center;
    overflow: hidden;
    background: var(--status-offline-color);
    @s online {
      background: var(--status-online-color);
    }
    @s good {
      background: var(--status-good-color);
    }
    @s hazardous {
      background: var(--status-hazardous-color);
    }
    @s moderate {
      background: var(--status-moderate-color);
      color: #4a4a4a;
    }
    @s sensitive {
      background: var(--status-sensitive-color);
    }
    @s unhealthy {
      background: var(--status-unhealthy-color);
    }
    @s very-unhealthy {
      background: var(--status-very-unhealthy-color);
    }
    @s excellent {
      background: var(--status-excellent-color);
    }

    @e mask {
      width: 90px;
      position: relative;
      &:before {
        content: "";
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        z-index: 1;
        left: -3px;
        top: 0;
        width: 100%;
        height: 100%;
        transform: skew(15deg);
      }

      &:after {
        content: attr(data-text);
        font-weight: bold;
        color: var(--text-dark-color);
        text-align: center;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    @e offline-text {
      flex: 1;
    }

    @e performance {
      flex: 1;
      font-weight: bold;
      text-align: center;
    }
  }

  @media (--only-mobile) {
    @b container {
      width: 100%;
    }
  }
}
