

@component-namespace location {
  @b wrapper {
    max-width: var(--desktop-min-width);
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  @b inner {
    width: var(--desktop-min-width);
    @m home {
      width: var(--desktop-min-width);
      padding-top: 20px;

      & .ant-tabs {
        overflow: visible;
      }
    }
  }
  @b inner .ant-tabs-tab-active {
    font-weight: 700 !important;
  }
  @media (--only-mobile) {
    @b inner {
      width: 100%!important;
      @m home {
        width: 100%!important;
        padding: 0 1rem;
      }
  }
}
}

@media (--only-not-desktop) {
  @component-namespace location {
    .ant-tabs-bar {
      display: none;
    }
    @b wrapper {
      overflow-x: auto;
    }
  }
}
